import React from 'react';
import * as FaIcons from "react-icons/fa";
import { MdOutlineDesignServices } from "react-icons/md"
export const sidebarData = [
  {
  title: 'Login',
  path: '/login',
  icons: <FaIcons.FaDoorOpen />,

  },

  {
  title: 'Articoli',
  path: '/articles',
  icons: <FaIcons.FaCubes />,
  cName: 'nav-text',
  
  },

  {
    title: 'Categorie',
    path: '/categories',
    icons: <FaIcons.FaCube/>,
    cName: 'nav-text',
    
    },

    {
      title: 'Company',
      path: '/company',
      icons: <MdOutlineDesignServices/>,
      cName: 'nav-text',
      
      },  

    
]