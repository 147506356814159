import {API, ApiResponseBase} from "./api_config";
import {ICategory} from "../utils/interfaces/ICategory";

export interface ApiCategoriesResponse extends ApiResponseBase {
    data: ICategory[];
}

function doPostCategories(data: any) {
    return API.postEncoded(`/v1/backoffice/categories`, data);
}

function doPutCategories(id: number | string, data: any) {
    return API.put(`/v1/backoffice/categories/${id}`, data);
}

function doDeleteCategories(id: number | string) {
    return API.DELETE(`/v1/backoffice/categories/${id}`);
}

function doPostAssignedCategory(article_id: number | string, category_id: number | string) {
    return API.post(`/v1/backoffice/articles/${article_id}/assignCategory/${category_id}`);
}

function doDeleteAssignedCategory(article_id: number | string, category_id: number | string) {
    // incorrect, I don't have the id of the pair
    return API.DELETE(`/v1/backoffice/articles/${article_id}/removeCategory/${category_id}`)
}

export {
    doPostCategories,
    doPutCategories,
    doDeleteCategories,
    doPostAssignedCategory,
    doDeleteAssignedCategory
}