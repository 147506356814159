import React, {useEffect, useState, useRef, useLayoutEffect} from 'react';
import useApi from '../../utils/custom_hooks/api';
import {useCompany} from '../../utils/contexts/company-context';
import Loading from '../ui/Loading';
import {useToast} from '../../utils/contexts/toast-context';
import {doGetWhoAmI} from '../../logic/auth';
import CompanyImageUploadButton from './companyImageUploadButton';
import PayOffRow from './payOffRow';
import {doPutCompany} from '../../logic/company';
import PayOffPreview from './payOffPreview';
import {IoRefresh, IoReloadOutline} from 'react-icons/io5';
import {animate, motion} from 'framer-motion';
import {Editor} from '@tinymce/tinymce-react';
import {FaSave} from 'react-icons/fa';
import MaxWidthDiv from './MaxWidthDiv';

const imgNotFound = process.env.PUBLIC_URL + '/close-x.png';


const PayOffForm = () => {
    const container = {
        hidden: {opacity: 0, scale: 1},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.05
            }
        },
        exit: {y: 20, opacity: 0}
    }
    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        },
        exit: {y: 20, opacity: 0}

    }


    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 768;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);


    const {call, loading} = useApi();

    const {displayMessage, displayWarning, displayError} = useToast();


    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 40,


    };


    const {initializeCompany, company} = useCompany();

    useEffect(() => {
        const load = async () => {
            if (!loading) {
                await call(
                    () => doGetWhoAmI(),
                    (res) => {
                        if (res.company) {
                            initializeCompany(res.company);
                        }
                    }
                )
            }

        }
        load();
    }, []);


    

    const payOff1 = React.useRef<HTMLInputElement>(null);
    const payOff2 = React.useRef<HTMLInputElement>(null);
    const payOff3 = React.useRef<HTMLInputElement>(null);
    const payOffColor1 = React.useRef({value: ''});
    const payOffColor2 = React.useRef<HTMLInputElement>(null);
    const payOffColor3 = React.useRef<HTMLInputElement>(null);

    const [isChanging, setIsChanging] = useState(false)

    const setFormValues = () => {
        if (payOff1.current && company?.pay_off && company?.background_color && payOffColor1.current) {
            payOff1.current.value = (company?.pay_off)
            payOffColor1.current.value = (company?.background_color)
        }

        if (payOff2.current && company?.second_pay_off && payOffColor2.current) {
            payOff2.current.value = (company?.second_pay_off)
            payOffColor2.current.value = (company?.second_pay_off_color)
        }

        if (company?.third_pay_off && payOffColor3.current) {

            payOffColor3.current.value = (company?.third_pay_off_color)
        }
    }


    useEffect(() => {
        setFormValues();

    }, []);

    const handleSubmit = async () => {
        if (payOff1.current && payOffColor1.current) {
            let pay_off = payOff1.current.value;
            let background_color = payOffColor1.current.value;
            const data = {
                pay_off,
                background_color,

            }
            await call(
                () => doPutCompany(data),
                (res) => {
                    if (res) {

                        displayMessage('Pay Off modificato');
                    }
                },
                (err) => {
                }
            )
        } else {
            (console.log('NO PARAMETERS'))
        }

        if (payOff2.current && payOffColor2.current) {
            let second_pay_off = payOff2.current.value;
            let second_pay_off_color = payOffColor2.current.value;
            const data = {
                second_pay_off,
                second_pay_off_color,

            }
            await call(
                () => doPutCompany(data),
                (res) => {
                    if (res) {

                        displayMessage('Pay Off modificato');
                    }
                },
                (err) => {
                }
            )
        } else {
            (console.log('NO PARAMETERS'))
        }

        if (payOffColor3.current) {
            let third_pay_off = input3;
            let third_pay_off_color = payOffColor3.current.value;
            const data = {
                third_pay_off_color,
                third_pay_off,
            }
            await call(
                () => doPutCompany(data),
                (res) => {
                    if (res) {

                        displayMessage('Pay Off modificato');
                    }
                },
                (err) => {
                }
            )
        } else {
            (console.log('NO PARAMETERS'))
        }
        setIsChanging(false)
    }


    var container_style = {
        //backgroundImage: `url("${company?.logo_url}")`,  da usare effettivamente
        backgroundImage: `url("https://www.net-evolution.com/wp-content/uploads/2021/01/netevolution-fav.jpg.png")`,  //esempio
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '',
    }

    if (payOffColor1.current) {
        container_style = {
            backgroundImage: `url("https://www.net-evolution.com/wp-content/uploads/2021/01/netevolution-fav.jpg.png")`,  //esempio

            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundColor: payOffColor1.current.value
        }
    }

    const [payOffBg1, setPayOffBg] = useState(company?.background_color);
    const bgUpdater = (color: string) => {
        setPayOffBg(color);
    }
    const [payOffBg2, setpayOffBg2] = useState(company?.second_pay_off_color);
    const bgUpdater2 = (color: string) => {
        setpayOffBg2(color);
    }
    const [payOffBg3, setpayOffBg3] = useState(company?.third_pay_off_color);
    const bgUpdater3 = (color: string) => {
        setpayOffBg3(color);
    }

    const [input1, setinput1] = useState(company?.pay_off);
    const inputUpdater1 = (input: string) => {

        setinput1(input);
    }

    const [input2, setinput2] = useState(company?.second_pay_off);
    const inputUpdater2 = (input: string) => {
        setinput2(input);
    }

    const [input3, setinput3] = useState(company?.third_pay_off);
    const inputUpdater3 = (input: string) => {
        setinput3(input);
    }

    if (payOffBg1) {
        container_style.backgroundColor = payOffBg1
    }


    const [editorContent, setEditorContent] = useState(company?.third_pay_off);

    const handleEditorChange = (content: string) => {
        setEditorContent(content);
        inputUpdater3(content);

        setIsChanging(true)
    };

    if (company) return (<motion.div variants={container} initial='hidden' animate='visible'
                                     className='flex flex-col w-full items-center'>
            <motion.div variants={item}
                        className='overflow-hidden shadow-xl bg-white h-fit w-full m-3 rounded-[7px] p-[10px]'>
                <div className='flex flex-row justify-between w-full items-center'>
                    <div className="font-bold text-2xl uppercase mb-[10px]">{company.company_name}</div>

                </div>

                <div className='flex flex-row items-center gap-[30px] px-0 py-1 my-[2px]'>
                    <div className='font-semibold min-w-[65px]'>Logo</div>
                    <div style={container_style} className="flex w-24 h-12 rounded">
                        {/* <CompanyImageUploadButton/> */}
                    </div>
                </div>

                <div className='h-[1px] w-full bg-[#dfdfdf] rounded-[2px]'></div>
                <PayOffRow payOffColor={payOffColor1} payOffName={payOff1} title={'PayOff 1'} onUpdate={handleSubmit}
                           bgUpdater={bgUpdater} inputUpdater={inputUpdater1}/>
                <div className='h-[1px] w-full bg-[#dfdfdf] rounded-[2px]'></div>
                <PayOffRow payOffColor={payOffColor2} payOffName={payOff2} title={'PayOff 2'} onUpdate={handleSubmit}
                           bgUpdater={bgUpdater2} inputUpdater={inputUpdater2}/>
                <div className='h-[1px] w-full bg-[#dfdfdf] rounded-[2px]'></div>
                {/* <PayOffRow payOffColor={payOffColor3} payOffName={''} title={'Descrizione'} onUpdate={handleSubmit}
                           bgUpdater={bgUpdater3} inputUpdater={inputUpdater3}/> */}


                {/* <MaxWidthDiv>

                    <Editor
                        apiKey=''
                        initialValue={company.third_pay_off}


                        init={{

                            placeholder: 'Inserisci Descrizione...',
                            branding: false,
                            height: 200,

                            menubar: false,
                            font_formats:
                                "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                            plugins:
                                "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                            toolbar:
                                "undo redo | fontfamily fontsize | bold italic underline strikethrough | forecolor blockquote | link image | alignleft aligncenter alignright alignjustify  | removeformat ",
                            statusbar: false,

                        }}
                        onEditorChange={handleEditorChange}
                    /></MaxWidthDiv>{isChanging ?
                <div
                    className="cursor-pointer h-6 w-6 flex justify-center items-center hover:text-[green] rounded-[6px] duration-[150ms] active:scale-[0.9] scale-[0.95]"
                    onClick={handleSubmit}>
                    <FaSave title="Salva" className="h-5 w-5"/></div>
                :
                <div
                    className="h-6 w-6 flex justify-center items-center  rounded-[6px] opacity-0 duration-[300ms] scale-[0.95]">
                    <FaSave title="Salva" className="h-5 w-5"/></div>} */}

            </motion.div>

            <motion.div variants={item}
                        className='overflow-hidden shadow-xl bg-white h-fit w-full m-3 rounded-[7px] p-[10px]'>
                <div className='font-semibold min-w-[65px] text-[20px]'>Attuale</div>
                <PayOffPreview payOffBg1={payOffBg1} payOffBg2={payOffBg2} payOffBg3={payOffBg3}
                               payOff1={input1 ? input1 : ''} payOff2={input2 ? input2 : ''}
                               payOff3={input3 ? input3 : ''}/>
            </motion.div>


        </motion.div>

    );
    else return (console.log('No_Company'), null);
};

export default PayOffForm;