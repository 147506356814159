import React from 'react';
import Loading from "./Loading";

type Props = {
    loading?: boolean,
    className?: string,
    loadingLabel?: string,
    onConfirm?: (e: React.FormEvent<HTMLFormElement>) => void,
    onUndo?: () => void,
    confirmLabel?: string,
}

const ModalButtonSection = ({
                                loading = false,
                                loadingLabel = '',
                                className = '',
                                confirmLabel = 'Conferma',
                                onConfirm,
                                onUndo
                            }: Props) => {

    const handleClose = () => {

        onUndo && onUndo();
    }

    const handleConfirm = (e: any) => {
        onConfirm && onConfirm(e);
    }
    const BUTTON_CLASS = 'min-h-[40px] min-w-[100px] rounded-[5px] border-2 border-black'
    return (
        <div className={className}>
            {loading ?
                <Loading label={loadingLabel}/>
                :
                <div className="flex flex-row justify-between">
                    <button onClick={handleClose} type="button"
                            className='BUTTON_CLASS hover:bg-[#ce0900] border-[#ce0900]  text-red-600 hover:border-[#ce0900] duration-[150ms] m-2'>Annulla
                    </button>
                    <button onClick={handleConfirm} type="submit"
                            className='BUTTON_CLASS hover:bg-[green] border-[green]  text-green-600 hover:border-[green]  duration-[150ms] m-2'>{confirmLabel}</button>
                </div>
            }
        </div>
    );
};

export default ModalButtonSection;