import {API, ApiResponseBase} from "./api_config";
import {IArticle} from "../utils/interfaces/IArticle";

const COMPANY = process.env.REACT_APP_COMPANY;

export interface ApiArticleResponse extends ApiResponseBase {
    data: IArticle[];
}


async function doGetArticle(id: string | undefined) {
    return API.get(`/v1/expressleather/companies/${COMPANY}/articles/${id}`);
}

async function doPutArticle(id: string, data?: any) {
    return API.put(`/v1/backoffice/articles/${id}`, data);
}


async function doPostArticle(data: any) {
    return API.postEncoded('/v1/backoffice/articles', data);
}

async function doDeleteArticle(id: string | number) {
    return API.DELETE(`/v1/backoffice/articles/${id}`);
}

export {
    doGetArticle,
    doPutArticle,
    doPostArticle,
    doDeleteArticle
};
