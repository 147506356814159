import React, {SyntheticEvent} from 'react';
import {FaEdit, FaTrash} from "react-icons/fa";
import {useColor} from "../../utils/contexts/color-context";
import {useCompany} from "../../utils/contexts/company-context";
import ImageUploadButton from '../ui/ImageUploadButton';
import {MdDragIndicator} from "react-icons/md";
import {useLayoutEffect} from 'react';
import {useRef, useEffect, useState} from 'react';
import {doPutColor} from "../../logic/__mocks__/colors";
import useApi from "../../utils/custom_hooks/api";
import {useToast} from "../../utils/contexts/toast-context";
import {FaSave, FaUpload} from "react-icons/fa";
import {motion, AnimatePresence} from 'framer-motion';
import DashButton from './DashButton';
import {useQueryClient} from 'react-query';
import {CgEnter} from 'react-icons/cg';
import DeleteColorModal from './DeleteColorModal';
import EditColorForm from './EditColorForm';
import ConditionalOverlay from '../ui/ConditionalOverlay';

type Props = {
    color: any,
    article: any,
    index: any,
    dragHandle: any,
}

type ColorProps = {
    color: any;
    index: any;
    article: any;
    dragHandle?: any;
    lang: any;
    EditFormOpener: () => void;
    
};

const Color: React.FC<ColorProps> = ({color, index, article, dragHandle, lang, EditFormOpener}) => {

    // console.log('index', index)
    const queryClient = useQueryClient();


    const {selectColorById, openDeleteColorForm, } = useColor();
    const {selectArticleById} = useCompany();
    


    const container_style = {
        //DA UTILIZZARE
        backgroundImage: `url("${color.image_url}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    const [editOpen, setEditOpen] = useState(false);
    const handleEditClick = () => {
        selectArticleById(article.id);
        selectColorById(color.id);
        
        EditFormOpener();
        getposition();
        setEditOpen(true);
    }

    const handleDelete = () => {
        selectArticleById(article.id);
        selectColorById(color.id);
        openDeleteColorForm();
        setOpenDelete(true);
    }


    const [coordinates, setCoordinates] = useState({top: 0, left: 0});
    const elementRef = useRef<HTMLDivElement>(null)
    const getposition = () => {

        if (elementRef.current) {
            const {left, top} = elementRef.current.getBoundingClientRect();
            setCoordinates({top, left});
        }
    }


    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 35,

    };


    const {closeEditColorForm, getSelectedColorFromArticle, selectedColorId} = useColor();
    const {selectedArticle, selectedArticleId, updateColor} = useCompany();


    const {call, loading} = useApi();
    const {displayMessage, displayError} = useToast();

    const [isChanging, setIsChanging] = useState(false);
    const [isActive, setIsActive] = useState(color.active)

    const handleSubmit = async (e: any) => {
        setIsActive(e.target.checked);
        e.preventDefault();

        const active = e.target.checked

        const data = {
            active
        }

        await call(
            () => doPutColor(color.id, data),
            (res) => {
                if (res && selectedArticleId) {
                    updateColor(selectedArticleId, res);
                    closeEditColorForm();
                }
                displayMessage('Color edited')
            },
            (error) => {
                console.log("Error in editing color", error);
                displayError("error editing color");
            }
        )

        queryClient.invalidateQueries('getArticles');
    }


    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 987;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);

    const [openDelete, setOpenDelete] = useState(false)
    const DeleteFormCloser = () => setOpenDelete(false);
    const [deleteModalStyle, setDeleteModalStyle] = useState<React.CSSProperties>({});
    const ColorRef = React.useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        setTimeout(function () {
            if (ColorRef.current) {
                const {left, top, height, width} = ColorRef.current.getBoundingClientRect();

                const left2 = left;
                const newDeleteModalStyle: React.CSSProperties = {
                    position: `absolute`,

                    height: `100%`,
                    width: `${width}px`,
                };
                setDeleteModalStyle(newDeleteModalStyle);
            }
        })
    }, []);


    const [nameIt, setNameIt] = useState(color?.['translations']?.[3]?.['text']);
    const [nameEng, setNameEng] = useState(color?.['translations']?.[2]?.['text']);

    const setFormValues = () => {
        {
            setNameIt(color?.['translations']?.[3]?.['text'])
        }
        {
            setNameEng(color?.['translations']?.[2]?.['text'])
        }

    }
    useEffect(() => {
        setTimeout(setFormValues, 0)

    }, []);
    useEffect(() => {
        setTimeout(setFormValues, 0)

    }, [lang]);

    return (
        <>


            <motion.div className="bg-blue-100  flex-col  items-center w-[100%] rounded-[2px]  w-[250px] relative"
                        ref={ColorRef}>
                {openDelete ? <DeleteColorModal DeleteFormCloser={DeleteFormCloser} formStyle={deleteModalStyle}/> : ''}
                <div {...dragHandle} className={"flex-.5 flex flex-row"}>
                    {/* <MdDragIndicator size={20} /> */}
                    {/* <div className="text-gray-500">{index + 1}</div> */}
                </div>
                <div className="flex w-[100%] flex-col items-center justify-between">
                    <div className='flex flex-col items-center w-full'>
                        <div className='rounded bg-[#9ca3af] w-full h-[120px]'>
                            <div style={container_style} className="flex  rounded w-full h-[120px]">
                                <ImageUploadButton id={color.id} type='colors'></ImageUploadButton>
                            </div>
                        </div>
                        <div className='flex flex-row items-center justify-between mx-[10px] w-full'>

                            <div
                                className="text-gray-800 text-[20px] flex items-center  w-full px-[8px]">{/*{color.name ? color.name : <span className="text-gray-400 text-xs">Nome colore non inserito</span>}*/}
                                {
                                    color.translations.map((obj: any) => {
                                        if (obj.lang == lang)
                                            return (obj.field == 'name') ? (
                                                <AnimatePresence>
                                                    <motion.div key={index + 1} initial={{y: -5, opacity: 0}}
                                                                animate={{y: 0, opacity: 1}} exit={{y: +5}}
                                                                className="">
                                                        {(obj.lang === 'en') ? <motion.p initial={{y: -5, opacity: 0}}
                                                                                         animate={{y: 0, opacity: 1}}
                                                                                         exit={{y: +5}}>{nameEng}</motion.p> :
                                                            <motion.p initial={{y: -5, opacity: 0}}
                                                                      animate={{y: 0, opacity: 1}}
                                                                      exit={{y: +5}}>{nameIt}</motion.p>}</motion.div>
                                                </AnimatePresence>
                                            ) : ''

                                    })

                                }
                            </div>
                            <div className='pr-[10px] text-gray-400 text-[20px]'>{color.weight}</div>
                        </div>
                    </div>
                    {/* <div>ID{color.id}</div>
                    <div>WEIGHT {color.weight}</div> */}
                    <div className="flex flex-row justify-between items-center gap-[3px] w-full px-[5px] pb-[5px]">
                        <div className='flex flex-row items-center'>
                            <div className='scale-[0.8]'><DashButton isActive={isActive} onChange={handleSubmit}/></div>
                            {isActive ? <label htmlFor="is_active" className='min-w-[54px] '> Attivo </label> :
                                <label htmlFor="is_active" className='min-w-[54px] opacity-60  '> Inattivo </label>}
                        </div>
                        <div className='flex flex-row items-center'>
                            <div
                                className="text-[#343434] cursor-pointer hover:text-[darkcyan]  h-6 w-6 flex justify-center items-center rounded-[6px] hover:scale-[1.1] duration-[150ms] active:scale-[0.9]"
                                onClick={handleEditClick}><FaEdit className="h-[4em w-[4em]"/></div>
                            {<div
                                className="cursor-pointer h-6 w-6 flex justify-center items-center text-[#e70c0c] rounded-[6px] hover:scale-[1.1]  duration-[300ms]">
                                <FaTrash onClick={handleDelete} className="h-4 w-4"/></div>}</div>

                    </div>
                </div>

            </motion.div>
            

        </>

    );
};

export default Color;