import React, {useState} from "react";
import {ICompany as Company} from "../interfaces/ICompany";

type ProviderProps = {
    children: React.ReactNode,
}


type CompanyContextType = {
    company?: Company,
    initializeCompany: (company: Company) => void,
    articles?: any[],
    loadArticles: (articles: any[]) => void,
    colors?: any[],
    //loadColors: (colors: any[]) => void,
    loadColors: (article_id: number, colors: any[]) => void,
    selectedArticleId: number | undefined,
    selectedColorId: number | undefined,
    selectArticleById: (id: number | undefined) => void,
    selectedArticle: () => any | undefined,
    updateArticle: (article: any) => void,
    addArticle: (article: any) => void,
    removeArticle: (article: any) => void,
    addColor: (article_id: number, color: any) => void,
    updateColor: (article_id: number, color: any) => void,
    removeColor: (article_id: number, color_id: number) => void,
    selectedLang: string;
    selectLang: (selectedLang: string) => void,
    checkSameName:(group:any, dataNum : number, ref:any, item:any) => boolean,


}

const CompanyContext = React.createContext<CompanyContextType | undefined>(undefined);

function useCompany() {
    const context = React.useContext(CompanyContext);
    if (!context) {
        throw new Error('useCompany must be used within a CompanyProvider');
    }
    return context;
}

function CompanyProvider({children}: ProviderProps) {
    const value = useCompanyContextValue()
    return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
}

function useCompanyContextValue() {

    const [company, setCompany] = useState<Company | undefined>();
    const [articles, setArticles] = useState<any[]>();
    const [colors, setColors] = useState<any[]>();
    const [selectedArticleId, setSelectedArticleId] = useState<number | undefined>();
    const [selectedColorId, setSelectedColorId] = useState<number | undefined>();
    const [selectedLang, setSelectedLang] = useState<string>('it');


    const initializeCompany = (company: Company) => setCompany(company);
    const loadArticles = (articles: any[]) => setArticles(articles);
    // const loadColors = (colors: any[]) => setColors(colors);

    const selectArticleById = (id: number | undefined) => setSelectedArticleId(id);
    const selectLang = (selectedLang: string) => {
        setSelectedLang(selectedLang);
    }

    const selectedArticle = () => {
        if (articles) {
            const _filtered = articles.filter(article => article.id === selectedArticleId);
            if (_filtered && _filtered[0]) {
                return _filtered[0];
            }
        }

        return null;
    }

    const updateArticle = (article_to_update: any) => {
        if (articles) {
            const _articles = articles.map((article) => {
                if (article_to_update.id == article.id) {
                    return {...article_to_update};
                } else return {...article}
            });
            setArticles([..._articles]);
        }
    }

    const addArticle = (article_to_add: any) => {
        if (articles) setArticles([{...article_to_add}, ...articles]);
    }
    const removeArticle = (article_to_delete: any) => {
        if (articles) {
            const _articles = articles.filter((article) => article_to_delete.id != article.id);
            setArticles([..._articles]);
        }
    }

    const addColor = (article_id: number, color: any) => {
        if (articles) {
            const _articles = articles.map((article) => {
                if (article.id == article_id) {
                    return {
                        ...article,
                        colors: [...article.colors, {...color}]
                    }
                } else {
                    return {...article}
                }
            });

            setArticles([..._articles]);

        }
    }

    const loadColors = (article_id: number, colors: any) => {
        if (articles) {
            const _articles = articles.map((article) => {
                if (article.id == article_id) {
                    return {
                        ...article,
                        colors: [...colors]
                    }
                } else {
                    return {...article}
                }
            });

            setArticles([..._articles]);

        }
    }

    const updateColor = (article_id: number, color: any) => {
        if (articles) {
            const _articles = articles.map((article) => {
                if (article.id == article_id) {
                    if (article.colors && article.colors.length > 0) {
                        let _colors = article.colors.map((_color: any) => {
                            if (_color.id == color.id) {
                                return {...color}
                            } else return {..._color}
                        });
                        setColors(_colors);
                        return {...article, colors: [..._colors]}
                    }
                }
                return {...article}
            });

            setArticles([..._articles]);
        }
    }
    const removeColor = (article_id: number, color_id: number) => {
        if (articles) {

            let _articles = articles.map((article) => {
                if (article.id == article_id) {

                    let _colors = article.colors.filter((color: any) => color.id != color_id);
                    return {
                        ...article,
                        colors: [..._colors]
                    }
                }
                return {...article};
            });
            setArticles([..._articles]);

        }
    }

    const checkSameName = (group:any, dataNum : any, ref:any, item:any) => { let sameName = false;
        group && (group.forEach((innerItem : any) => {

            
                if(ref.current?.value.toLowerCase() === (innerItem?.['translations']?.[dataNum]?.['text'].toLowerCase())){
                    if(item.id == innerItem.id) { /*console.log('STESSO ID', (innerItem?.['translations']?.[dataNum]?.['text'].toLowerCase()));*/ return false};
                    sameName = true;
                    /*console.log('NOME USATO',(innerItem?.['translations']?.[dataNum]?.['text'].toLowerCase()));*/ return true;
                }else { /*console.log('DIVERSO', (innerItem?.['translations']?.[dataNum]?.['text'].toLowerCase()));*/return false};
             
        }));
        /*console.log('sameName', sameName);*/return sameName;

    }

    return {
        company, initializeCompany,
        articles, loadArticles,
        selectedArticleId, selectedArticle, selectArticleById,
        updateArticle, addArticle, removeArticle,
        addColor, updateColor, removeColor, selectedColorId, loadColors, colors,
        selectLang, selectedLang,
        checkSameName,
    }
}

export {
    CompanyProvider,
    useCompany,
}
