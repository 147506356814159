import React, {useState, SyntheticEvent, useEffect} from 'react';
import {FaEdit, FaTrash, FaSave, FaUpload} from "react-icons/fa";
import Color from "./Color";
import {useCompany} from "../../utils/contexts/company-context";
import {useArticle} from "../../utils/contexts/article-context";
import {useColor} from "../../utils/contexts/color-context";
import ArticleDetails from "./ArticleDetails";
import useApi from '../../utils/custom_hooks/api';
import {doPutArticle} from "../../logic/articles";
import {doPutTranslation} from '../../logic/translations';
import {useToast} from '../../utils/contexts/toast-context';
import ReactCountryFlag from "react-country-flag"
import ImageUploadButton from '../ui/ImageUploadButton';
import {motion, AnimatePresence} from 'framer-motion';
import Layout from '../ui/layout/Layout';
import DashButton from './DashButton';
import {IoIosArrowForward} from 'react-icons/io';
import ArticleDetailsMobile from './articleDetailsMobile';
import DeleteArticleModal from './DeleteArticleModal';
import ConditionalOverlay from '../ui/ConditionalOverlay';

type Props = {
    article: any,
}

const ArticleRowMobile = ({article}: Props) => {

    const [open, setOpen] = useState(false);
    const {selectArticleById} = useCompany();
    const {
        openEditArticleForm,
        openDeleteArticleForm,
        isCreateArticleButtonOpen,
        openCreateArticleButton,
        deleteArticleFormStatus
    } = useArticle();
    const {openCreateColorForm} = useColor();
    const [isActive, setIsActive] = useState(article.active);
    const {call} = useApi();
    const {selectedArticle, selectedArticleId, updateArticle,} = useCompany();


    const {displayMessage} = useToast();
    const [isChanging, setIsChanging] = useState(false)

    const backgroundImage = `url("${article.image_url}")`;

    const handleDelete = (e: any) => {
        e.stopPropagation();
        selectArticleById(article.id);
        openDeleteArticleForm();
    }

    const handleChange = (e: any) => {
        setIsChanging(true)
        selectArticleById(article.id);
        const {type, name, value, checked} = e.target;
        if (type === 'checkbox' && name === 'is_active') {
            setIsActive(e.target.checked)
        }
    }

    const nameRefIta = React.useRef<HTMLInputElement>(null);
    const nameRefEn = React.useRef<HTMLInputElement>(null);
    const descriptionRefIta = React.useRef<HTMLTextAreaElement>(null);
    const descriptionRefEn = React.useRef<HTMLTextAreaElement>(null);


    const handleSubmit = async (e: any) => {


        if (nameRefEn.current) {
            const name = nameRefEn.current.value;
            const data = {
                name
            }
            await call(
                () => doPutTranslation('articles', article.id, 'name', 'en', data),
                (res) => {
                    if (res) {
                        updateArticle(res);
                        displayMessage('Articolo modificato');
                    }
                },
                (err) => {
                }
            )
        }

        if (nameRefIta.current) {
            const name = nameRefIta.current.value;
            const data = {
                name
            }
            await call(
                () => doPutTranslation('articles', article.id, 'name', 'it', data),
                (res) => {
                    if (res) {
                        updateArticle(res);
                        displayMessage('Articolo modificato');
                    }
                },
                (err) => {
                }
            )
        }

        if (descriptionRefEn.current) {
            const description = descriptionRefEn.current.value;
            const data = {
                description
            }
            await call(
                () => doPutTranslation('articles', article.id, 'description', 'en', data),
                (res) => {
                    if (res) {
                        updateArticle(res);
                        displayMessage('Articolo modificato');
                    }
                },
                (err) => {
                }
            )
        }

        if (descriptionRefIta.current) {
            const description = descriptionRefIta.current.value;
            const data = {
                description
            }
            await call(
                () => doPutTranslation('articles', article.id, 'description', 'it', data),
                (res) => {
                    if (res) {
                        updateArticle(res);
                        displayMessage('Articolo modificato');
                    }
                },
                (err) => {
                }
            )
        }
        setIsChanging(false)
    }

    const DashSubmit = async (e: any) => {
        setIsActive(e.target.checked);
        e.prevendivefault();
        if (true) {
            const active = e.target.checked

            const data = {
                active
            }

            await call(
                () => doPutArticle(article.id, data),
                (res) => {
                    if (res) {
                        updateArticle(res);


                    }
                },
                (err) => {
                }
            )
        }


        setIsChanging(false)
    }


    const setFormValues = () => {
        if (nameRefIta.current && descriptionRefIta.current
        ) {
            nameRefIta.current.value = (article?.['translations']?.[3]?.['text'])
            descriptionRefIta.current.value = (article?.['translations']?.[1]?.['text'])
        }
        if (nameRefEn.current
            && descriptionRefEn.current) {
            nameRefEn.current.value = (article?.['translations']?.[2]?.['text'])
            descriptionRefEn.current.value = (article?.['translations']?.[0]?.['text'])
        }
    }

    useEffect(() => {
        setFormValues();

    }, []);

    const container_style = {
        backgroundImage: `url("${article.image_url}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    const handleOpenColors = () => {
        setOpen(!open);
        openCreateArticleButton(!isCreateArticleButtonOpen);
    }

    const motionrow = {
        type: "spring",
        stiffness: 700,
        damping: 80,
    };
    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 40,
    };


    const [lingua, setLingua] = useState('it');
    const itLingua = () => {
        setLingua('it');
        setTimeout(setFormValues, 0);
    };
    const enLingua = () => {
        setLingua('en');
        setTimeout(setFormValues, 0);
    };

    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 678;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);

    return (
        <motion.div className='overflow-hidden shadow-xl bg-white h-[100%] flex-shrink-1 rounded-[5px]'
                    transition={motionrow} style={windowSize ? {} : {}}>
            <motion.div className='flex items-center flex-col h-[fit-content]'>

                <div className='flex flex-row items-center w-full gap-[10px] px-[13px] justify-between'>
                    <div className='flex flex-row gap-[10px]'>
                        <div className="object-fill flex justify-center">
                            <motion.button className=' text-[black] origin-center'
                                           animate={open ? {rotate: 90} : {}} transition={spring}
                                           onClick={handleOpenColors}><IoIosArrowForward className='scale-[2]'/>
                            </motion.button>
                        </div>

                        <div className="font-[15px] py-[5px] ">

                            {
                                article.translations.map((obj: any, index: any) => {
                                    if (obj.lang == lingua)
                                        return (obj.field == 'name') ? (

                                            <p title="Nome" key={index + 1}
                                               className=' my-1 flex flex-row gap-[10px] border-[1px] text-[18px] border-[lightgray] rounded-[5px]'>


                                                <motion.input initial={{y: -5, opacity: 0}} animate={{y: 0, opacity: 1}}
                                                              exit={{y: +5}}
                                                              className="cursor-default w-full focus:cursor-text  rounded-[5px] px-[5px]"
                                                              placeholder={(obj.lang === 'en') ? 'Name' : 'Nome'}
                                                              name="name"
                                                              ref={(obj.lang === 'en') ? nameRefEn : nameRefIta}
                                                              onChange={handleChange}
                                                />
                                            </p>
                                        ) : ''

                                })

                            }


                        </div>
                    </div>
                    {/*<div className='flex flex-row gap-[10px]'>*/}
                    {/*    <ReactCountryFlag countryCode={'it'} style={{width: '2em', minWidth: '2em', height: 'auto'}} svg*/}
                    {/*                      className={(lingua == 'it') ? 'opacity-1 duration-[150ms]' : 'opacity-[0.3] duration-[150ms]'}*/}
                    {/*                      onClick={itLingua}/>*/}
                    {/*    <ReactCountryFlag countryCode={'gb'} style={{width: '2em', minWidth: '2em', height: 'auto'}} svg*/}
                    {/*                      className={(lingua == 'en') ? 'opacity-1 duration-[150ms]' : 'opacity-[0.3] duration-[150ms]'}*/}
                    {/*                      onClick={enLingua}/>*/}
                    {/*</div>*/}
                </div>

                <div className="object-fill w-full flex justify-center px-[15px] flex-shrink-1">
                    <div className=" flex h-[180px] w-full items-center flex-shrink-1">
                        <div style={container_style}
                             className="w-full h-full rounded flex items-center justify-center flex-shrink-1">
                            <div
                                className="flex flex-row items-center justify-center h-full w-full prova_img rounded-lg bg-image flex-shrink-1">
                                <ImageUploadButton id={article.id} type='articles'></ImageUploadButton>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="w-full px-[13px] mt-[5px]">{
                    article.translations.map((obj: any, index: any) => {
                        if (obj.lang == lingua)
                            return (obj.field == 'description') ? (

                                <p title="descrizione" key={index + 1}
                                   className='my-1 flex flex-row items-center gap-[20px]  bg-[white] border-[1px] border-[lightgray]  rounded-[5px]'>

                                    <motion.textarea initial={{y: -5, opacity: 0}} animate={{y: 0, opacity: 1}}
                                                     exit={{y: +5}}
                                                     className="cursor-default focus:cursor-text w-full rounded-[5px] p-[3px]"
                                                     placeholder={(obj.lang === 'en') ? 'Description' : 'Descrizione'}
                                                     name="description"
                                                     ref={(obj.lang === 'en') ? descriptionRefEn : descriptionRefIta}
                                                     onChange={handleChange}
                                    />
                                </p>
                            ) : ''
                    })
                }
                </div>

                <div className="object-fill flex flex-row items-center w-full justify-between m-2 px-[10px]">
                    <div className="">
                        <div className="object-fill flex justify-center gap-[10px] items-center mx-[5px] ">
                            {/*<input type="checkbox" name="is_active" checked={isActive} onChange={handleChange} />*/}
                            <DashButton isActive={isActive} onChange={DashSubmit}/>
                            {isActive ? <label htmlFor="is_active" className='min-w-[54px]'> Attivo </label> :
                                <label htmlFor="is_active" className='min-w-[54px] opacity-60'> Inattivo </label>}</div>
                    </div>
                    <div className="flex flex-row items-center gap-[5px] duration-[300ms]">

                        {isChanging ?
                            <div
                                className="cursor-pointer h-6 w-6 flex justify-center items-center hover:text-[green] rounded-[6px] duration-[150ms] active:scale-[0.9] scale-[0.95]">
                                <FaSave onClick={handleSubmit}
                                        title="Salva"
                                        className="h-5 w-5"/></div> : <div
                                className="h-6 w-6 flex justify-center items-center  rounded-[6px] opacity-0 duration-[300ms] scale-[0.95]">
                                <FaSave
                                    title="Salva"
                                    className="h-5 w-5"/></div>}
                        <div>
                            <div className="object-fill flex justify-center">
                                <div className="flex flex-row items-center">
                                    <div
                                        className={`w-full m-[4px] rounded-[5px] h-[auto]  font-medium flex flex-col justify-center items-center px-[12px] ${article.colors.length > 0 ? "bg-gray-300" : "bg-red-400 text-white"}`}>
                                        {article.colors.length}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {article.colors && article.colors.length > 0 ? <label title="elimina i colori per eliminare"
                                                                              className="h-6 w-6 flex justify-center items-center text-[#e70c0c] rounded-[6px]  duration-[300ms] opacity-30"><FaTrash
                                className="h-4 w-4"/></label>
                            : <div
                                className="cursor-pointer h-[auto] w-6 flex justify-center items-center text-[#e70c0c] rounded-[6px] hover:scale-[1.1]  duration-[300ms]">
                                <FaTrash onClick={handleDelete} className="h-4 w-4"/></div>}
                    </div>

                </div>


            </motion.div>
            <div className={open ? '' : 'h-[10px]'}></div>
            {open &&
                <div className=''><ArticleDetailsMobile article={article}/></div>
            }
            <div className={open ? 'h-[10px] w-full' : ''}></div>

        </motion.div>
    );
};

export default ArticleRowMobile;
