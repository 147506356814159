import React from 'react';
import {useCompany} from "../../utils/contexts/company-context";
import {useArticle} from "../../utils/contexts/article-context";
import useApi from "../../utils/custom_hooks/api";
import {useToast} from "../../utils/contexts/toast-context";
import {doDeleteArticle} from "../../logic/articles";
import {doDeleteColor} from "../../logic/__mocks__/colors";
import {useColor} from "../../utils/contexts/color-context";
import {useQueryClient, useQueryErrorResetBoundary} from 'react-query';
import {motion} from 'framer-motion';
import {MdOutlineCancel} from 'react-icons/md';
import ModalButtonSection from "../ui/ModalButtonSection";

type DeleteColorModalProps = {
    formStyle: any;
    DeleteFormCloser: () => void;
};
const DeleteColorModal: React.FC<DeleteColorModalProps> = ({DeleteFormCloser, formStyle}) => {
    const queryClient = useQueryClient();
    const {selectedArticleId, removeColor} = useCompany();
    const {call, loading} = useApi();
    const {displayMessage} = useToast();
    const {selectedColorId, closeDeleteColorForm} = useColor();

    const handleClose = () => {
        closeDeleteColorForm();
    }

    const handleConfirm = async () => {
        if (selectedArticleId && selectedColorId) {

            await call(
                () => doDeleteColor(selectedColorId),
                (deleted_res) => {
                    if (deleted_res.id) {
                        removeColor(selectedArticleId, deleted_res.id);
                        displayMessage('Colore eliminato');
                        closeDeleteColorForm();
                    }
                    queryClient.invalidateQueries('getArticles')
                }
            )
        }
    }

    return (<>

            <motion.div
                className="bg-[black] backdrop-blur-[3.5px] backdrop-filter bg-opacity-25 rounded p-4 flex  items-center absolute z-[999] flex-col cursor-default"
                style={formStyle} initial={{opacity: 0}} animate={{opacity: 1}}>
                <div></div>
                <div className="flex flex-row justify-center items-center w-full h-full">
                    <div className="flex flex-row justify-center items-center w-full h-full">
                        <ModalButtonSection loading={loading}
                                            loadingLabel='Cancellazione in corso'
                                            onUndo={DeleteFormCloser}
                                            onConfirm={handleConfirm}
                        />
                    </div>
                </div>

            </motion.div>
        </>
    );
};

export default DeleteColorModal;