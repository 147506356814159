import React, {useState, useRef, useEffect, useLayoutEffect} from 'react';
import Color from "./Color";
import {useCompany} from "../../utils/contexts/company-context";
import ArticleCategories from "./ArticleCategories";
import {doPostColor} from '../../logic/colors';
import useApi from '../../utils/custom_hooks/api';
import {useToast} from '../../utils/contexts/toast-context';
import {DragDropContext, Draggable, Droppable,} from "react-beautiful-dnd";
import {doMoveWeight} from '../../logic/weightsMover';
import {AnimatePresence, animate, color, delay, motion, spring} from 'framer-motion';
import {exit} from 'process';
import {FaPlus} from "react-icons/fa";

type Props = {
    article: any
}


const ArticleDetailsMobile = ({article}: Props) => {

    //const [colors, setColors] = useState(article.colors)
    const {selectArticleById, selectedArticleId, addColor, loadColors, colors} = useCompany();
    const {call} = useApi();
    const {displayMessage} = useToast();


    const handleOnDragEnd = async (color: any) => {

        if (!color.destination) return (console.log('no_Color_Destination'));

        if (article.colors) {

            let sourceIndex = parseInt(color.source.index) + 1;
            let destinationIndex = parseInt(color.destination.index) + 1;

            let row_id = color.draggableId.replace("item_", "");
            row_id = row_id.substring(0, row_id.indexOf("_"));

            const old_rows = [...article.colors];
            const items = Array.from(article.colors);

            const [reorderedItems] = items.splice(color.source.index, 1);
            items.splice(color.destination.index, 0, reorderedItems);

            items.map((item: any, index) => {
                item.weight = index;
            });

            let response = await doMoveWeight(row_id, 'colors', destinationIndex);
            if (response) {

            } else {
                loadColors(article.id, old_rows);

            }
        }
    }

    const handleOnDragStart = (result: any) => {
        if (!result) return;
    }

    const container = {
        hidden: {opacity: 0, scale: 1},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.05
            }
        },
        exit: {y: 20, opacity: 0}
    }

    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        },
        exit: {y: 20, opacity: 0}

    }


    const tuttoButtonRef = useRef<HTMLButtonElement>(null);
    const buttonContainerRef = useRef<HTMLDivElement>(null);
    const attiviButtonRef = useRef<HTMLButtonElement>(null);
    const inattiviButtonRef = useRef<HTMLButtonElement>(null);
    const [indStyle, setIndStyle] = useState<React.CSSProperties>({});
    const [containerStyle, setContainerStyle] = useState<React.CSSProperties>({});
    const [filterState, setFilterState] = useState('Tutto');
    const handleButtonClick = (buttonRef: React.RefObject<HTMLButtonElement>, filter: string) => {
        if (buttonRef.current && buttonContainerRef.current) {
            const {left, top, width} = buttonRef.current.getBoundingClientRect();
            let bg = '';
            if (filter == 'Inattivi') {
                bg = 'red'
            }
            if (filter == 'Attivi') {
                bg = 'green'
            }
            if (filter == 'Tutto') {
                bg = 'darkcyan'
            }
            const left2 = left - buttonContainerRef.current.getBoundingClientRect().x;
            const newIndStyle: React.CSSProperties = {
                position: 'relative',
                left: `${left2}px`,
                width: `${width}px`,
                backgroundColor: `${bg}`,
            };
            setIndStyle(newIndStyle);
            setFilterState(filter);

        }
    };

    useLayoutEffect(() => {
        setTimeout(function () {
            if (tuttoButtonRef.current && buttonContainerRef.current) {
                const {left, top, width} = tuttoButtonRef.current.getBoundingClientRect();

                const left2 = left - buttonContainerRef.current.getBoundingClientRect().x;

                const initialIndStyle: React.CSSProperties = {
                    position: 'relative',
                    left: `${left2}px`,
                    width: `${width}px`,
                    backgroundColor: `darkcyan`,

                };

                setIndStyle(initialIndStyle);
                setFilterState('Tutto');
            }
        })
    }, []);

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 40,
        duration: 150,

    };

    const motionRow = {
        type: "spring",
        stiffness: 700,
        damping: 80,
    };
    const [searchTerm, setSearchTerm] = useState<string>('');
    const searchbar =
        <input className='border-2 border-[lightgray] p-[2px] rounded-lg outline-none'
               type="text"
               placeholder="Cerca..."
               value={searchTerm}
               onChange={e => setSearchTerm(e.target.value)}
        />

    const [isHovered, setIsHovered] = useState(false);

    const addButtonRef = useRef<HTMLButtonElement>(null);


    const handleResize = (entries: ResizeObserverEntry[]) => {
        const entry = entries[0];
        const {width} = entry.contentRect;

        if (width >= 120) {
            setIsHovered(true);
        } else {
            setIsHovered(false);
        }
    };
    useEffect(() => {


        const resizeObserver = new ResizeObserver(handleResize);

        if (addButtonRef.current) {
            resizeObserver.observe(addButtonRef.current);
        }

        return () => {
            if (addButtonRef.current) {
                resizeObserver.unobserve(addButtonRef.current);
            }
        };
    }, []);
    const constraintsRef = useRef(null);

    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 987;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);

    {/*useEffect(() => {
    loadColors(article.id, article.colors);
}, []);*/
    }

    return (

        <AnimatePresence>{/*<motion.div className='relative' >
      <motion.div  className="m-0 p-0 relative origin-top top-[-4px] w-full" initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        transition={motionRow}

      >
        <motion.div className="relative px-[5px] border-y-[0.5rem] w-full border-y-[transparent] bg-[white] border-b-[white] rounded-b-[5px] className=bg-[lightgray] w-[100%]">
          <motion.div className="p-2 rounded w-[100%]">
            <ArticleCategories article={article} />
            <div className="px-8 py-2 w-[100%]"></div>

            <div className='p-1 border-[lightgray] border-2 rounded-[5px] w-[100%] relative'>

              <div className={'flex flex-row w-[100%] justify-between items-center relative right-0 pr-[18px] flex-wrap'}>
                <div className='text-[30px] relative'>Colori</div>

                <div className={'flex flex-row gap-[20px] items-center flex-wrap'}>

                  <motion.div className='flex flex-col justify-center relative top-[3px]'>
                    <div className="flex flex-row relative right-0">
                      <button ref={tuttoButtonRef} onClick={() => handleButtonClick(tuttoButtonRef, 'Tutto')} className='px-2'>Tutti</button>
                      <button ref={attiviButtonRef} onClick={() => handleButtonClick(attiviButtonRef, 'Attivi')} className='px-2'>Attivi</button>
                      <button ref={inattiviButtonRef} onClick={() => handleButtonClick(inattiviButtonRef, 'Inattivi')} className='px-2'>Inattivi</button>
                    </div>
                    <div id="cont" ref={buttonContainerRef} className="relative h-[7px] w-[100%] opacity-30"><motion.div animate={{ x: indStyle.left, width: indStyle.width, height: 20, backgroundColor: indStyle.backgroundColor, y: -22 }} transition={spring} id="ind" className="ind bg-opacity-30 rounded-[5px] h-[100%] h-[20px]" /></div>
                  </motion.div>
                  <div className='flex flex-row items-center gap-[10px]'>
                  {searchbar}
                  <motion.button
                    initial={{ borderRadius: 100, width: 30 }} whileHover={{ borderRadius: 5, width: 135 }} transition={spring} ref={addButtonRef} onClick={handleAddColor}
                    className="bg-[#3782f1] text-[#fafcff] cursor-pointer bg-opacity-70 roundedn-full h-[30px] items-center justify-center p-1 flex items-center rounded-full hover:bg-opacity-100">

                    
                    {isHovered ? 'Aggiungi Colore' : <FaPlus />}
                  </motion.button></div>
                </div>
              </div>
              <div className='w-full h-[5px] bg-gradient-to-b from-white to-black-]'></div>

              <motion.div className='h-[400px] overflow-auto pr-1'>

                {article.colors && article.colors.length > 0 ?
                  <><DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
                    <Droppable droppableId="recipeInfoSheet" direction='horizontal'>
                      {(provided) => (
                        <motion.div
                          className="flex flex-col text-[#000] gap-2"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          variants={container}
                          initial="hidden"
                          animate="visible"
                        >
                          {article.colors &&
                            article.colors.map((dragItem: any, index: any) => { 
                              console.log('color', dragItem)
                              console.log('indexint', index)
                              if (
                                ((filterState === 'Attivi' && dragItem.active === true) ||
                                  (filterState === 'Tutto') ||
                                  (filterState === 'Inattivi' && dragItem.active === false)) &&
                                  dragItem.name.toLowerCase().includes(searchTerm.toLowerCase()) === true
                              ) {
                                return (
                                  <AnimatePresence key={index}>
                                    <motion.div variants={item} className='w-full'>
                                      <Draggable
                                        key={dragItem.id + '_' + index}
                                        draggableId={`item_${dragItem.id + '_' + index}`}
                                        index={index} 
                                      >
                                        {(provided) => (
                                          <div ref={provided.innerRef} {...provided.draggableProps}>
                                            <Color
                                              color={dragItem}
                                              index={index}
                                              article={article}
                                              dragHandle={provided.dragHandleProps}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    </motion.div>
                                  </AnimatePresence>
                                );
                              }
                            })}
                          {provided.placeholder}
                        </motion.div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  </>
                  :
                  <div className='min-h-[70px]'>Nessun colore disponibile</div>
                }
              </motion.div></div>

          </motion.div>

        </motion.div>
      </motion.div>
    </motion.div>*/}
        </AnimatePresence>
    );
};

export default ArticleDetailsMobile;