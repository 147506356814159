import {useQuery, UseQueryResult} from "react-query";
import {API, CustomAxiosError} from "../../logic/api_config";
import {ICategory} from "../interfaces/ICategory";

async function doGetCategories(): Promise<ICategory[]> {
    try {
        const response = await API.get(`/v1/backoffice/categories`);
        return response?.data.data;
    } catch (error) {
        console.log("error in useApiGetCategories hook", error)
        throw error;
    }
}

const useApiGetCategories = (): UseQueryResult<ICategory[], CustomAxiosError> => {
    return useQuery<ICategory[], CustomAxiosError>("getCategories", () => doGetCategories());
};

export default useApiGetCategories;