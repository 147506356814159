import React, {useEffect, SyntheticEvent, useState, useLayoutEffect} from 'react';
import {FaSave, FaTrash} from "react-icons/fa";
import {useCategory} from "../../utils/contexts/categories-context";
import ReactCountryFlag from "react-country-flag"
import useApi from '../../utils/custom_hooks/api';
import {doPutCategories} from '../../logic/categories';
import {useToast} from '../../utils/contexts/toast-context';
import {doPutTranslation} from '../../logic/translations';
import {AnimatePresence, motion} from 'framer-motion';
import DashButton from '../Articles/DashButton';
import {CgEnter} from 'react-icons/cg';
import {ICategory} from "../../utils/interfaces/ICategory";
import {useQueryClient} from 'react-query';
import DeleteCategoryModal from './DeleteCategoryModal';
import useApiGetCategories from '../../utils/custom_hooks/useApiGetCategories';
import { useCompany } from '../../utils/contexts/company-context';


type Props = {
    category: ICategory
}

const Category = ({category}: Props) => {
    const queryClient = useQueryClient();
    const {call} = useApi();
    const nameRefIta = React.useRef<HTMLInputElement>(null);
    const nameRefEn = React.useRef<HTMLInputElement>(null);
    const descriptionRefIta = React.useRef<HTMLTextAreaElement>(null);
    const descriptionRefEn = React.useRef<HTMLTextAreaElement>(null);
    const imageRef = React.useRef<HTMLInputElement>(null);
    const {displayMessage, displayError} = useToast();

    const {openDeleteCategoryModal, selectCategoryById, updateCategory} = useCategory();

    const [isActive, setIsActive] = useState(category.active);
    const [isChanging, setIsChanging] = useState(false)
    const { checkSameName } = useCompany();

    // const handleEdit = () => {
    //     selectCategoryById(category.id);
    //     openEditCategoryModal();
    // }
const { loadCategories } = useCategory();

    const {data: categories} = useApiGetCategories()
    useEffect(() => {
        if (categories) {
            loadCategories(categories);
        }
    }, [categories]);


    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (imageRef.current) {
            const image_url = imageRef.current.value;
            const active = isActive

            const data = {
                image_url,
                active
            }

            await call(
                () => doPutCategories(category.id, data),
                (res) => {
                    if (res) {
                        updateCategory(res);
                        displayMessage('Categoria Modificata');
                    }
                },
                (err) => {
                }
            )
        }

        if (nameRefEn.current && (nameRefEn.current.value != (category?.['translations']?.[2]?.['text'])) && checkSameName(categories, 2, nameRefEn, category) == false) {
            const name = nameRefEn.current.value;
            const data = {
                name
            }
            await call(
                () => doPutTranslation('categories', category.id, 'name', 'en', data),
(res) => {
                    if (res) {
                        queryClient.invalidateQueries('getCategories')
                        displayMessage('Categoria Modificata');
                    }
                },
                (err) => {
                }
            )
        }else{if(checkSameName(categories, 2, nameRefEn, category) == true){displayError('Nome Inglese già in uso')}}

        if (nameRefIta.current && nameRefIta.current.value != (category?.['translations']?.[3]?.['text']) && checkSameName(categories, 3, nameRefIta, category) == false) {
            const name = nameRefIta.current.value;
            const data = {
                name
            }
            await call(
                () => doPutTranslation('categories', category.id, 'name', 'it', data),
                (res) => {
                    if (res) {
                        queryClient.invalidateQueries('getCategories')
                        displayMessage('Categoria Modificata');
                    }
                },
                (err) => {
                }
            )
        }else{if(checkSameName(categories, 3, nameRefIta, category) == true){displayError('Nome italiano già in uso')}}

        if (descriptionRefEn.current && descriptionRefEn.current.value != (category?.['translations']?.[0]?.['text'])) {
            const description = descriptionRefEn.current.value;
            const data = {
                description
            }
            await call(
                () => doPutTranslation('categories', category.id, 'description', 'en', data),
                (res) => {
                    if (res) {
                        queryClient.invalidateQueries('getCategories')
                        displayMessage('Categoria Modificata');
                    }
                },
                (err) => {
                }
            )
        }

        if (descriptionRefIta.current && descriptionRefIta.current.value != (category?.['translations']?.[1]?.['text'])) {
            const description = descriptionRefIta.current.value;
            const data = {
                description
            }
            await call(
                () => doPutTranslation('categories', category.id, 'description', 'it', data),
                (res) => {
                    if (res) {
                        queryClient.invalidateQueries('getCategories')
                        displayMessage('Categoria Modificata');
                    }
                },
                (err) => {
                }
            )
        }
        setIsChanging(false)
    }

    const DashSubmit = async (e: any) => {
        setIsActive(e.target.checked);
        e.preventDefault();
        if (true) {
            const active = e.target.checked

            const data = {
                active
            }

            await call(
                () => doPutCategories(category.id, data),
                (res) => {
                    if (res) {
                        queryClient.invalidateQueries('getCategories')

                    }
                },
                (err) => {
                }
            )
        }
    }


    const handleChange = (e: any) => {
        setIsChanging(true)
        selectCategoryById(category.id);
        const {type, name, value, checked} = e.target;
        if (type === 'checkbox' && name === 'is_active') {
            setIsActive(e.target.checked);
        }

    }

    const handleDelete = () => {
        selectCategoryById(category.id);
        openDeleteCategoryModal();
        setOpenDelete(true);
    }

    const setFormValues = () => {
        if (nameRefIta.current && descriptionRefIta.current
        ) {
            nameRefIta.current.value = (category?.['translations']?.[3]?.['text'])
            descriptionRefIta.current.value = (category?.['translations']?.[1]?.['text'])
        }
        if (nameRefEn.current
            && descriptionRefEn.current) {
            nameRefEn.current.value = (category?.['translations']?.[2]?.['text'])
            descriptionRefEn.current.value = (category?.['translations']?.[0]?.['text'])
        }
    }

    useEffect(() => {
        setFormValues();

    }, []);

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 35,
    };


    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        },
        exit: {y: 20, opacity: 0}

    }

    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 450;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);

    const [lingua, setLingua] = useState('it');
    const itLingua = () => {
        setLingua('it');
        setTimeout(setFormValues, 0);
    };
    const enLingua = () => {
        setLingua('en');
        setTimeout(setFormValues, 0);
    };

    const [openDelete, setOpenDelete] = useState(false)
    const DeleteFormCloser = () => setOpenDelete(false);

    const [deleteModalStyle, setDeleteModalStyle] = useState<React.CSSProperties>({});
    const CategoryRef = React.useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        setTimeout(function () {
            if (CategoryRef.current) {
                const {left, top, height, width} = CategoryRef.current.getBoundingClientRect();

                const left2 = left;
                const newDeleteModalStyle: React.CSSProperties = {
                    position: `absolute`,

                    height: `${height}px`,
                    width: `${width}px`,
                };
                setDeleteModalStyle(newDeleteModalStyle);
            }
        })
    }, []);

    return (

        <motion.div variants={item} key={category.id} ref={CategoryRef}
                    className=" rounded overflow-clip shadow-xl bg-white ring-1 ring-[lightgray] ring-opacity-50 "
                    style={windowSize ? {width: '100%', marginRight: '5%', marginLeft: '5%'} : {}}>
            {openDelete ? <DeleteCategoryModal DeleteFormCloser={DeleteFormCloser} formStyle={deleteModalStyle}/> : ''}
            <div className='flex flex-col gap-[15px] pt-2 px-2 items-center'>

                <div className='w-full h-auto'>
                    <div className='flex flex-row items-center w-full justify-between items-center rounded-[5px]'>
                        <div className="">{
                            category.translations.map((obj: any, index: any) => {
                                if (obj.lang == lingua)
                                    return (obj.field == 'name') ? (

                                        <p title="Nome" key={index + 1}
                                           className='flex mx-1 border-[1px] border-[lightgray] rounded-[5px]'>


                                            <AnimatePresence>
                                                <motion.input initial={{y: -5, opacity: 0}} animate={{y: 0, opacity: 1}}
                                                              exit={{y: +5}}

                                                              className="px-1 px-[5px] rounded-[5px] flex"
                                                              placeholder={(obj.lang === 'en') ? 'Name' : 'Nome'}
                                                              name="name"
                                                              ref={(obj.lang === 'en') ? nameRefEn : nameRefIta}
                                                              //onChange={handleChange} //per salvataggio manuale
                                                              onBlur={handleSubmit}
                                                />
                                            </AnimatePresence>
                                        </p>
                                    ) : ''

                            })

                        }</div>

                        <div className='flex flex-row gap-[10px] px-[7px] shrink'>
                            <ReactCountryFlag countryCode={'it'} style={{width: '2em', minWidth: '2em', height: 'auto'}}
                                              svg
                                              className={(lingua == 'it') ? 'opacity-1 duration-[150ms]' : 'cursor-pointer opacity-[0.3] duration-[150ms]'}
                                              onClick={itLingua}/>
                            <ReactCountryFlag countryCode={'gb'} style={{width: '2em', minWidth: '2em', height: 'auto'}}
                                              svg
                                              className={(lingua == 'en') ? 'opacity-1 duration-[150ms]' : 'cursor-pointer opacity-[0.3] duration-[150ms]'}
                                              onClick={enLingua}/>
                        </div>
                    </div>

                    <div className="">{
                        category.translations.map((obj: any, index: any) => {
                            if (obj.lang == lingua)
                                return (obj.field == 'description') ? (

                                    <p title="descrizione" key={index + 1}
                                       className='m-1 flex flex-row items-center gap-[20px] bg-[white] border-[1px] border-[lightgray] shadow-[lightgray] shadow-inner rounded-[5px]'>

                                        <motion.textarea initial={{y: -5, opacity: 0}} animate={{y: 0, opacity: 1}}
                                                         exit={{y: +5}}
                                                         className="w-full px-1 py-1 px-1 py-1 rounded-[5px]"
                                                         placeholder={(obj.lang === 'en') ? 'Description' : 'Descrizione'}
                                                         name="description"
                                                         ref={(obj.lang === 'en') ? descriptionRefEn : descriptionRefIta}
                                            //onChange={handleChange} //per salvataggio manuale
                                                         onBlur={handleSubmit}
                                        />
                                    </p>
                                ) : ''
                        })
                    }
                    </div>
                </div>


            </div>

            {/*<div>
                <input placeholder="image_url"
                    name="image_url"
                    ref={imageRef} />
                </div>*/}

            <div className='flex flex-row py-2 px-1 w-full justify-between items-center'>
                <div className="object-fill flex justify-center gap-[10px] items-center mx-[5px] relative">
                    {/*<input type="checkbox" name="is_active" checked={isActive} onChange={handleChange} />*/}
                    <div className='scale-[0.9]'><DashButton isActive={isActive} onChange={DashSubmit}/></div>
                    {isActive ? <label htmlFor="is_active" className='min-w-[54px] duration-[50ms]'> Attivo </label> :
                        <label htmlFor="is_active"
                               className='min-w-[54px] opacity-60 duration-[50ms]'> Inattivo </label>}</div>

                <div className="flex flex-row relative" id="save_delete">
                    {/* {isChanging ?
                        <div
                            className="cursor-pointer h-6 w-6 flex justify-center items-center hover:text-[green] rounded-[6px] duration-[150ms] active:scale-[0.9] scale-[0.95]">
                            <FaSave onClick={handleSubmit}
                                    title="Salva"
                                    className="h-5 w-5"/></div> : <div
                            className="h-6 w-6 flex justify-center items-center  rounded-[6px] opacity-0 duration-[300ms] scale-[0.95]">
                            <FaSave
                                title="Salva"
                                className="h-5 w-5"/></div>} Salvataggio Manuale della modifica*/}
                    {isActive ? <label title="Non eliminabile finchè attiva"
                                       className="h-6 w-6 flex justify-center items-center text-[#e70c0c] rounded-[6px]  duration-[300ms] opacity-30">
                            <FaTrash className="h-4 w-4"/></label>
                        : <div
                            className="cursor-pointer h-6 w-6 flex justify-center items-center text-[#e70c0c] rounded-[6px] hover:scale-[1.1]  duration-[300ms]">
                            <FaTrash onClick={handleDelete} className="h-4 w-4"/></div>}

                </div>

            </div>

        </motion.div>
    );
};

export default Category;
