import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MainProviders from "./components/app/MainProviders";
import NavBar from './components/ui/layout/Navigation/NavBar';
import './i18n';
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
    
);
root.render(
    <div className='contenitore scrollbar-none'>
    <BrowserRouter>
    <NavBar/>
        <MainProviders>
            <App/>
        </MainProviders>
    </BrowserRouter></div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
