import React from 'react';
import {useCategory} from "../../utils/contexts/categories-context";
import ToggleableCategory from "./ToggleableCategory";
import {motion} from 'framer-motion';
import useApiGetCategories from "../../utils/custom_hooks/useApiGetCategories";
import {useCompany} from "../../utils/contexts/company-context";

type Props = {
    article: any
}

const ArticleCategories = ({article}: Props) => {
    const {loadCategories} = useCategory();
    const {data} = useApiGetCategories();
    if (data) {
        loadCategories(data);
    }


    const toggleableCategories = () => {
        if (data && data.length > 0) {
            const activeCategories = data.filter((category) => category.active);

            return activeCategories.map((category) => {
                const hasCategory = article.categories && article.categories.some((article_category: any) => article_category.id === category.id);
                return {
                    ...category,
                    active: hasCategory,
                };
            });
        }
        return [];
    }

    const container = {
        hidden: {opacity: 0, scale: 1},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.05
            }
        },
        exit: {y: 20, opacity: 0}
    }


    return (
        <div className='w-full border-2 border-[lightgray] rounded-[5px] p-2 gap-5 overflow-hidden shadow-xl'>
            <div className="font-medium text-sm uppercase">Seleziona le categorie appartenenti a questo articolo:</div>
            <div className='flex flex-wrap'>
                {data && data.length > 0 ?
                    <motion.div variants={container} initial='hidden' animate='visible'
                                className="flex flex-row flex-wrap items-center gap-1 min-w-[fit-content]">
                        {toggleableCategories().map((cat) =>
                            <ToggleableCategory category={cat} key={cat.id} article={article}/>
                        )}
                    </motion.div>
                    :
                    <div className="text-yellow-600">Nessuna Categoria inserita</div>
                }
            </div>
        </div>
    );
};

export default ArticleCategories;