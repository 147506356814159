import React, {Fragment, useEffect, useState} from 'react';
import Navigation from "./components/app/Navigation";
import {useAuth} from "./utils/contexts/auth-context";
import LoadingScreen from "./pages/LoadingScreen";
import {QueryClient, QueryClientProvider} from "react-query";

const queryCLient = new QueryClient()

function App() {
    return (
        <QueryClientProvider client={queryCLient}>
            <Navigation/>
        </QueryClientProvider>
    );
}

export default App;
