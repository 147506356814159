import React from 'react';
import {ImExit} from 'react-icons/im';
import {Link, NavLink} from "react-router-dom";
import NavBar from "./Navigation/NavBar";
import {useToast} from "../../../utils/contexts/toast-context";
import {motion} from 'framer-motion';
import {useCompany} from '../../../utils/contexts/company-context';
import {NONAME} from 'dns';
import {doGetWhoAmI} from '../../../logic/auth';
import Loading from '../Loading';
import useApi from '../../../utils/custom_hooks/api';
import {useState, useEffect} from 'react';
import {MdHeight} from 'react-icons/md';
import {useAuth} from '../../../utils/contexts/auth-context';
import {IUser} from "../../../utils/interfaces/IUser";

type Props = {
    children: React.ReactNode,
    contentClassName?: string,
    title?: string,
}

const Layout = ({children, title, contentClassName = ""}: Props) => {
    const [windowSize, setWindowSize] = useState<boolean>(false);
    const {toastContent, toastOpen} = useToast();
    const {call} = useApi();

    const [user, setUser] = useState<IUser | undefined>();


    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 768;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);

    const layoutHeight: number = window.innerHeight - 50;


    const [isOpen, setOpen] = React.useState(false);

    const canvasStyle = {
        display: "flex",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center"
    };

    const menuButtonStyle = {
        marginLeft: "2rem"
    };

    const container = {
        hidden: {opacity: 0, scale: 1},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.05
            }
        },
        exit: {y: 20, opacity: 0}
    }

    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        },
        exit: {y: 20, opacity: 0}

    }


    useEffect(() => {
        const load = async () => {
            await call(
                () => doGetWhoAmI(),
                (res) => {
                    setUser(res);
                }
            )
        }

        load();
    }, []);

    const auth = useAuth();
    const signOut = auth.signout;

    return (
        <div className="scrollbar-none flex-1 flex flex-col bg-[#ededed] md:flex-row lg:flex-row xl:flex-row 2xl:flex-row relative layoutContainer min-h-screen"
            style={windowSize ? {paddingTop: 50} : {paddingLeft: 35}}>
            {/*<div id="NAV1" className='bg-black hidden sm:block'><NavBar /></div>*/}
            <div className={windowSize ? "" : 'flex flex-col  flex-1 relative'}>

                {windowSize ? '' : <div className="bg-eBLUE flex flex-row justify-between fixed z-[200] w-[100%]">
                    <div
                        className="flex flex-row items-center items-baseline gap-2 w-2/3 whitespace-nowrap text-[12px] justify between py-2">
                        <div className="text-white font-bold">BACKOFFICE - Express Leather</div>
                        <div className="text-white">{title}</div>
                    </div>

                    <NavLink to={'/login'}
                             className='text-[12px] justify between flex flex-row items-center gap-[10px] text-white font-bold hover:bg-gray-600 py-2 pr-[53px] pl-[18px] cursor-pointer'
                             onClick={signOut}>
                        <div className=''>ESCI</div>
                        <div className='overflow-hidden items-center text-white scale-[1.2]'><ImExit/></div>
                    </NavLink>
                </div>}


                <div className={`flex-1 flex flex-col ${contentClassName}`}>
                    {children}
                </div>


                <div className={"bg-eBLUE p-2 flex flex-row justify-end fixed w-screen bottom-0 left-[0] z-[200]"}>
                    <div className="text-white text-xs">
                        <span>powered by </span>
                        <a href="https://www.net-evolution.com/" className=" font-medium">
                            netEVOLUTION for {user?.company.company_name}
                        </a>
                    </div>
                </div>
            </div>

            {toastOpen &&
                <motion.div className="pointer-events-none fixed bottom-10 w-full flex flex-row justify-center"
                            layout transition={{
                    stiffness: 200,
                    damping: 80
                }}>
                    {toastContent}
                </motion.div>
            }
        </div>
    );
};

export default Layout;