import React, {useState, useRef, useEffect, useLayoutEffect, useMemo} from 'react';
import Color from "./Color";
import {useCompany} from "../../utils/contexts/company-context";
import ArticleCategories from "./ArticleCategories";
import {doPostColor} from '../../logic/colors';
import useApi from '../../utils/custom_hooks/api';
import {useToast} from '../../utils/contexts/toast-context';
import {doMoveWeight} from '../../logic/weightsMover';
import {motion} from 'framer-motion';
import {FaPlus} from "react-icons/fa";
import {ListManager} from "react-beautiful-dnd-grid";
import {useQueryClient} from "react-query";
import {IArticle} from "../../utils/interfaces/IArticle";
import {IColor} from "../../utils/interfaces/IColor";
import ReactCountryFlag from 'react-country-flag';
import ConditionalOverlay from '../ui/ConditionalOverlay';
import EditColorForm from './EditColorForm';

type Props = {
    article: IArticle
}


const ArticleDetails = ({article}: Props) => {
    const [seletectedIdColor, setSelectedIdColor] = useState<number | undefined>(undefined)
    const {selectArticleById, selectedArticleId, addColor, loadColors, selectedLang, selectLang} = useCompany();
    const {call} = useApi();
    const {displayMessage} = useToast();
    const queryClient = useQueryClient()
    const memoizedColors = useMemo(() => article.colors, [article.colors]);

    const [editColorFormStatus, setEditColorFormStatus] = useState(false)
    const editFormOpener = () => {setEditColorFormStatus(true)};
    const editFormCloser = () => {setEditColorFormStatus(false)};



    const handleAddColor = async () => {
        selectArticleById(article.id);

        if (selectedArticleId) {
            const data = {
                name: '',
                description: '',
                weight: 0,
                article_id: selectedArticleId,
                image_url: '',
            }

            await call(
                () => doPostColor(selectedArticleId, data),
                (res: any) => {
                    addColor(article.id, res);
                    displayMessage('Colore aggiunto');
                },
                (err) => {
                    console.log("Error in adding color:", err);
                }
            )
        }
        queryClient.invalidateQueries('getArticles')
    }

    const updateIndexByDragAndDrop = (array: IColor[], id: number, newIndex: number) => {
        const indexToUpdate = array.findIndex((item) => item.id === id);

        if (indexToUpdate !== -1) {
            const objectToUpdate = array[indexToUpdate];

            const updatedArray = array.filter((item) => item.id !== id);

            updatedArray.splice(newIndex, 0, objectToUpdate);

            return updatedArray;
        }

        return array;
    };

    const handleOnDragEnd = async (sourceIndex: number, destinationIndex: number) => {

        let destination;
        if (!destinationIndex) {
            destination = 1;
        } else {
            destination = destinationIndex + 1;
        }

        if (memoizedColors) {
            if (seletectedIdColor) {
                const updatedColors = updateIndexByDragAndDrop(
                    memoizedColors,
                    seletectedIdColor,
                    destination - 1
                );

                article.colors = updatedColors;

                let response = await doMoveWeight(seletectedIdColor, 'colors', destination);
                if (response) {
                    queryClient.invalidateQueries('getArticles')
                } else {
                }
            }
        }
    };

    const [memorizedColors2, setMemorizedColors2] = useState(memoizedColors);

    const handleOnDragEnd2 = async (sourceIndex: number, destinationIndex: number) => {
        let destination;
        if (!destinationIndex) {
            destination = 1;
        } else {
            destination = destinationIndex + 1;
        }

        if (memorizedColors2) {
            if (seletectedIdColor) {
                const updatedColors = updateIndexByDragAndDrop(
                    memorizedColors2,
                    seletectedIdColor,
                    destination - 1
                );

                // Esegui un aggiornamento locale invece di assegnare tutto l'array
                article.colors = updatedColors;

                // Esegui la chiamata API solo per spostare il peso, senza invalidare tutte le query
                let response = await doMoveWeight(seletectedIdColor, 'colors', destination);
                if (response) {
                    // Invalida solo la query interessata invece di 'getArticles'
                    queryClient.invalidateQueries(['getArticleColors', seletectedIdColor]);
                } else {
                    // Potresti gestire l'errore in qualche modo
                }
            }
        }
    };


    const container = {
        hidden: {opacity: 0, scale: 1},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.05
            }
        },
        exit: {y: 20, opacity: 0}
    }

    const item2 = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        },
        exit: {y: 20, opacity: 0}

    }


    const tuttoButtonRef = useRef<HTMLButtonElement>(null);
    const buttonContainerRef = useRef<HTMLDivElement>(null);
    const attiviButtonRef = useRef<HTMLButtonElement>(null);
    const inattiviButtonRef = useRef<HTMLButtonElement>(null);
    const [indStyle, setIndStyle] = useState<React.CSSProperties>({});
    const [containerStyle, setContainerStyle] = useState<React.CSSProperties>({});
    const [filterState, setFilterState] = useState('Tutto');
    const handleButtonClick = (buttonRef: React.RefObject<HTMLButtonElement>, filter: string) => {
        if (buttonRef.current && buttonContainerRef.current) {
            const {left, top, width} = buttonRef.current.getBoundingClientRect();
            let bg = '';
            if (filter == 'Inattivi') {
                bg = 'red'
            }
            if (filter == 'Attivi') {
                bg = 'green'
            }
            if (filter == 'Tutto') {
                bg = 'darkcyan'
            }
            const left2 = left - buttonContainerRef.current.getBoundingClientRect().x;
            const newIndStyle: React.CSSProperties = {
                position: 'relative',
                left: `${left2}px`,
                width: `${width}px`,
                backgroundColor: `${bg}`,
            };
            setIndStyle(newIndStyle);
            setFilterState(filter);

        }
    };

    useLayoutEffect(() => {
        setTimeout(function () {
            if (tuttoButtonRef.current && buttonContainerRef.current) {
                const {left, top, width} = tuttoButtonRef.current.getBoundingClientRect();

                const left2 = left - buttonContainerRef.current.getBoundingClientRect().x;

                const initialIndStyle: React.CSSProperties = {
                    position: 'relative',
                    left: `${left2}px`,
                    width: `${width}px`,
                    backgroundColor: `darkcyan`,

                };

                setIndStyle(initialIndStyle);
                setFilterState('Tutto');

            }
        })
    }, []);

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 40,
        duration: 150,

    };

    const motionRow = {
        type: "spring",
        stiffness: 700,
        damping: 80,
    };
    const [searchTerm, setSearchTerm] = useState<string>('');
    const searchbar =
        <input className='border-2 border-[lightgray] p-[2px] rounded-lg outline-none'
               type="text"
               placeholder="Cerca..."
               value={searchTerm}
               onChange={e => setSearchTerm(e.target.value)}
        />

    const [isHovered, setIsHovered] = useState(false);

    const addButtonRef = useRef<HTMLButtonElement>(null);


    const handleResize = (entries: ResizeObserverEntry[]) => {
        const entry = entries[0];
        const {width} = entry.contentRect;

        if (width >= 120) {
            setIsHovered(true);
        } else {
            setIsHovered(false);
        }
    };
    useEffect(() => {


        const resizeObserver = new ResizeObserver(handleResize);

        if (addButtonRef.current) {
            resizeObserver.observe(addButtonRef.current);
        }

        return () => {
            if (addButtonRef.current) {
                resizeObserver.unobserve(addButtonRef.current);
            }
        };
    }, []);
    const constraintsRef = useRef(null);

    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 987;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);

    useEffect(() => {
        loadColors(article.id, article.colors);
    }, []);

    const renderedColors: any = [];

    article.colors.forEach((color) => {
        if (
            ((filterState === 'Attivi' && color.active === true) ||
                (filterState === 'Tutto') ||
                (filterState === 'Inattivi' && color.active === false)) &&
            color.name.toLowerCase().includes(searchTerm.toLowerCase()) === true
        ) {
            renderedColors.push(color);
        }
    });
   

    return (<>
        {article.id === selectedArticleId &&
        <motion.td colSpan={8} className="m-0 p-0 relative origin-top top-[-4px] w-full" initial={{scaleY: 0}}
                   animate={{scaleY: 1}}
                   transition={motionRow}
                   
        >
            <motion.div 
                className="relative px-8 border-y-[0.5rem] w-full border-y-[transparent] bg-[white] border-b-[white] shadow-lg shadow-black-500/40 rounded-b-[5px] className=bg-[lightgray] w-[100%]">
                <motion.div className="p-2 rounded w-[100%]">


                    

                        <>
                            <div className='flex'>
                                
                                <ArticleCategories article={article}/>
                                <div className='flex flex-row gap-[10px]  border-2 border-[lightgray] rounded-[5px] pr-[15px] pl-[10px] gap-5 overflow-hidden shadow-xl ml-[15px]'>
                                    <ReactCountryFlag countryCode={'it'}
                                                      style={{width: '2em', minWidth: '2em', height: 'auto'}} svg
                                                      className={(selectedLang === 'it') ? 'opacity-1 duration-[150ms]' : 'cursor-pointer opacity-[0.3] duration-[150ms]'}
                                                      onClick={() => selectLang('it')}/>
                                    <ReactCountryFlag countryCode={'gb'}
                                                      style={{width: '2em', minWidth: '2em', height: 'auto'}} svg
                                                      className={(selectedLang === 'en') ? 'opacity-1 duration-[150ms]' : 'cursor-pointer opacity-[0.3] duration-[150ms]'}
                                                      onClick={() => selectLang('en')}/>
                                </div>
                            </div>
                            <div className="px-8 py-2 w-[100%]"></div>
                            <div className='p-1 border-[lightgray] border-2 rounded-[5px] w-[100%] relative'>
                                <div
                                    className={windowSize ? 'flex flex-col w-[100%] relative right-0 pr-[18px]' : 'flex flex-row w-[100%] justify-between items-center relative right-0 pr-[18px]'}>
                                    <div className='text-[30px] relative'>Colori</div>

                                    <div
                                        className={windowSize ? 'flex flex-col ' : 'flex flex-row gap-[20px] items-center'}>
                                        <motion.div className='flex flex-col justify-center relative top-[3px]'>
                                            <div className="flex flex-row relative right-0">
                                                <button ref={tuttoButtonRef}
                                                        onClick={() => handleButtonClick(tuttoButtonRef, 'Tutto')}
                                                        className='px-2'>Tutti
                                                </button>
                                                <button ref={attiviButtonRef}
                                                        onClick={() => handleButtonClick(attiviButtonRef, 'Attivi')}
                                                        className='px-2'>Attivi
                                                </button>
                                                <button ref={inattiviButtonRef}
                                                        onClick={() => handleButtonClick(inattiviButtonRef, 'Inattivi')}
                                                        className='px-2'>Inattivi
                                                </button>
                                            </div>
                                            <div id="cont" ref={buttonContainerRef}
                                                 className="relative h-[7px] w-[100%] opacity-30">
                                                <motion.div animate={{
                                                    x: indStyle.left,
                                                    width: indStyle.width,
                                                    height: 20,
                                                    backgroundColor: indStyle.backgroundColor,
                                                    y: -22
                                                }} transition={spring} id="ind"
                                                            className="ind bg-opacity-30 rounded-[5px] h-[100%] h-[20px]"/>
                                            </div>
                                        </motion.div>
                                        <div className='flex flex-row items-center gap-[10px] pr-[7px]'>
                                            {searchbar}
                                            <motion.button
                                                initial={{borderRadius: 100, width: 30}}
                                                whileHover={{borderRadius: 5, width: 135}} transition={spring}
                                                ref={addButtonRef} onClick={handleAddColor}
                                                className="bg-[#3782f1] text-[#fafcff] cursor-pointer bg-opacity-70 roundedn-full h-[30px] justify-center p-1 flex items-center rounded-full hover:bg-opacity-100">

                                                {/*<motion.div className="origin-right" animate={isHovered? {display:'flex', opacity:1}:{display:'none'}}>Aggiungi Colore</motion.div>*/}
                                                {isHovered ? 'Aggiungi Colore' : <FaPlus/>}
                                            </motion.button>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full h-[5px] bg-gradient-to-b from-white to-black-]'></div>

                                <motion.div className='h-[400px] flex flex-row justify-center overflow-x-hidden pr-1 '>
                                    {article.colors && article.colors.length > 0 ?
                                        <>
                                            <motion.div
                                                className="flex flex-col items-start text-[#000] gap-2 "

                                                variants={container}
                                                initial="hidden"
                                                animate="visible"
                                            >
                                                <ListManager
                                                    items={renderedColors}
                                                    direction="horizontal"
                                                    maxItems={4}
                                                    render={(item) =>
                                                        <motion.div draggable
                                                                    onHoverEnd={() => setSelectedIdColor(item.id)}
                                                                    variants={item2}
                                                                    className='px-2 py-1'>
                                                            <Color
                                                                color={item}
                                                                index={item.weight - 1}
                                                                article={article}
                                                                lang={selectedLang}
                                                                EditFormOpener={editFormOpener}
                                                            />
                                                        </motion.div>}


                                                    onDragEnd={handleOnDragEnd}
                                                />

                                            </motion.div>


                                        </>
                                        :
                                        <div className='min-h-[70px]'>Nessun colore disponibile</div>
                                    }
                                </motion.div>
                            </div>
                        </>

                </motion.div>

            </motion.div>
            <ConditionalOverlay condition={editColorFormStatus}>
                <EditColorForm EditFormCloser={editFormCloser} colors={article.colors}/>
            </ConditionalOverlay>
        </motion.td>

                                }
                                
              </>);
};

export default ArticleDetails;

