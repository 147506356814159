import React, {useEffect, useState, useRef} from 'react';
import Layout from "../components/ui/layout/Layout";
import useApi from "../utils/custom_hooks/api";
import {useCompany} from "../utils/contexts/company-context";
import Loading from "../components/ui/Loading";
import PayOffForm from '../components/Company/payOffForm';
import {doGetWhoAmI} from "../logic/auth";


const CompanyScreen = () => {

    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 768;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);


    const {call, loading} = useApi();

    const [isHovered, setIsHovered] = useState(false);

    const addButtonRef = useRef<HTMLButtonElement>(null);


    const handleResize = (entries: ResizeObserverEntry[]) => {
        const entry = entries[0];
        const {width} = entry.contentRect;

        if (width >= 102) {
            setIsHovered(true);
        } else {
            setIsHovered(false);
        }
    };
    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);

        if (addButtonRef.current) {
            resizeObserver.observe(addButtonRef.current);
        }

        return () => {
            if (addButtonRef.current) {
                resizeObserver.unobserve(addButtonRef.current);
            }
        };
    }, []);

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 40,
        duration: 150,

    };


    const {initializeCompany} = useCompany();

    useEffect(() => {
        const load = async () => {
            if (!loading) {
                await call(
                    () => doGetWhoAmI(),
                    (res) => {
                        if (res.company) {
                            initializeCompany(res.company);
                        }
                    }
                )
            }

        }

        load();
    }, []);


    return (

        <Layout title="Company">
            {


            }

            {loading ?
                <Loading/>
                : <>
                    <div
                        className={windowSize ? 'w-full flex justify-center items-start h-[95vh] overflow-auto scrollbar-none px-[5px]' : 'w-full flex flex-col items-center px-[15px] items-start h-[96vh] overflow-auto scrollbar-none pt-[35px]'}>
                        <PayOffForm/>

                    </div>
                </>
            }

        </Layout>

    );
};

export default CompanyScreen;