import React from 'react';

type Props = {
    onClick?: () => void,
    children?: React.ReactNode,
    type?: 'button' | 'submit',
    className?: string,
}

const Button = ({children, type = 'button', className = '', onClick}: Props) => {

    const handleClick = () => {
        onClick && onClick();
    }

    return (
        <button type={type}
                className={`bg-eBLUE text-[#FFF] font-bold hover:bg-eBLUE300 px-2 py-1 ${className}`}
                onClick={handleClick}>
            {children}
        </button>
    );
};

export default Button;