import React, { useEffect, useState } from 'react';
import LabelledInput from "../ui/LabelledInput";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/contexts/auth-context";
import Loading from "../ui/Loading";
import { useToast } from '../../utils/contexts/toast-context';
import { doPostLogin } from '../../logic/auth';
import { error } from 'console';

const LoginForm = () => {

    const navigate = useNavigate();
    const { setIsAuthenticated } = useAuth();
    const { displayError } = useToast();
    const { saveAuthenticationData } = useAuth();
    const [loading, setLoading] = useState(false);

    

    const signin = async (user: string, password: string) => {
        let response = await doPostLogin(user, password);
        
        if (response) {
            if (response.data.status === "ko") { displayError('Credenziali Errate'); setIsAuthenticated(false); console.log('Login Not Valid') } 
            else{setIsAuthenticated(true)
            let {data} = response;
            if (data) {
                saveAuthenticationData(data, '/articles');
            }
        }}
            
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        let user = e.currentTarget.user.value;
        let password = e.currentTarget.password.value;

        if (user && password) {
            await signin(user, password);

        }
        setLoading(false);
    }



    return (
        <form className="shadow-lg p-2 bg-gray-100 rounded mt-[35px]" onSubmit={handleSubmit}>
            <h2 className="font-medium uppercase text-sm text-center text-gray-600">Effettua l'accesso</h2>
            <LabelledInput label="User"
                name="user"
                required={true}
                className="mb-2" />
            <LabelledInput label="Password"
                name="password"
                required={true}
                type="password" />
            <div className="mt-4">
                {loading ?
                    <div className="flex flex-row justify-center">
                        <Loading label="Accesso in corso" />
                    </div>
                    :
                    <Button className="w-full " type="submit">
                        Accedi
                    </Button>
                }
            </div>
        </form>
    );
};

export default LoginForm;