import axios, {AxiosInstance, AxiosError, AxiosResponse} from 'axios';
import response_interceptor from "../interceptors/axios_response_interceptor";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

async function get(url: string, params?: any) {
    try {
        const instance = await getInstance();
        if (params) {
            return await instance.get(ENDPOINT + url, {params: {...params}});
        }
        return await instance.get(ENDPOINT + url);
    } catch (error) {
        console.log("error in api_config", error)
        throw error;
    }
}

async function post(url: string, params?: any) {
    try {
        const instance = await getInstance();

        return await instance.post(ENDPOINT + url, {...params});
    } catch (error) {
        throw error;
    }
}

async function postUploader(url: string, params?: any) {
    try {
        const instance = await getInstanceUploader();

        return await instance.post(ENDPOINT + url, {...params});
    } catch (error) {
        throw error;
    }
}


async function postEncoded(url: string, params?: any) {
    try {
        const instance = await getInstance();
        return await instance.post(ENDPOINT + url, new URLSearchParams({...params}));
    } catch (error) {
        throw error;
    }
}

async function put(url: string, data?: any) {
    try {
        const instance = await getInstance();
        return await instance.put(ENDPOINT + url, {...data});
    } catch (error) {
        throw error;
    }
}


async function DELETE(url: string) {
    try {
        const instance = await getInstance();
        return await instance.delete(ENDPOINT + url);
    } catch (error) {
        throw error;
    }
}

async function getInstance() {
    let access_token = await localStorage.getItem('@access_token');
    let instance = axios.create({
        baseURL: ENDPOINT,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })

    response_interceptor(instance)
    return instance
}

async function getInstanceUploader() {
    let access_token = await localStorage.getItem('@access_token');

    let instance = axios.create({
        baseURL: ENDPOINT,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'multipart/form-data',
        }
    })
    response_interceptor(instance)
    return instance
}

interface jwt {
    created_at: string,
    deadline: string,
}

export interface ApiResponseBase {
    status: string;
    status_code: number;
    error: string,
    locale: string,
    pagination: any[];
    jwt: jwt;
}

export const API = {
    get,
    post,
    postEncoded,
    put,
    DELETE,
    postUploader,
}


export interface CustomAxiosError extends AxiosError<unknown, AxiosResponse<unknown>> {
    response: {
        data: {
            detail: string;
        };
        status: number;
        statusText: string;
        headers: any;
        config: any;
    };
}