import React from 'react';
import { useToast } from '../../utils/contexts/toast-context';
import { doPostUpload } from '../../logic/uploader';
import { FaUpload } from "react-icons/fa";
import { useQueryClient } from 'react-query';

const ImageUploadButton = ({ id, type }) => {

    const { displayMessage } = useToast()
    const queryClient = useQueryClient()
    let file = '';

    const uploadHandler = async (e) => {
        file = e.target.files[0]

        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {

            file.isUploading = true
            displayMessage('Caricamento in corso')

            const image_url = file;

            const data = {
                image_url,
            }

            let response = await doPostUpload(id, type, data);
            if (response) {
                if (response.data.message === "File caricato correttamente") {
                    file.isUploading = false
                    displayMessage('Caricamento completato')
                    queryClient.invalidateQueries('getArticles')
                } else {
                    console.log('Upload interrotto', response.data.message);
                    displayMessage('Caricamento interrotto')
                }
            }


        } else {
            file.isUploading = false;
            displayMessage('Richiesto file JPEG/JPG/PNG')
        }
    }

    return (
        <>
            <label title="Carica Immagine" 
                className="text-white py-1.5 px-6 rounded bg-[darkgray] backdrop-blur-[2px] backdrop-filter bg-opacity-20 cursor-pointer text-xs flex items-center justify-center opacity-0 hover:opacity-[1] h-full w-full duration-[300ms]">
                
                <i className="fa-solid fa-image"></i>
                <input name='file' onChange={uploadHandler} type='file' className="hidden" />
                <FaUpload className="cursor-pointer h-5 w-5"></FaUpload>
            </label>
        </>
    )
}

export default ImageUploadButton