import React, {useEffect, useState} from 'react';
import { doGetArticle } from '../../logic/articles';
import { useCompany } from '../../utils/contexts/company-context';
import useApi from '../../utils/custom_hooks/api';
import { motion, useAnimation } from 'framer-motion';
import {IoHomeOutline, IoCartOutline} from 'react-icons/io5'
import MaxWidthDiv from './MaxWidthDiv';
type PayOffPreviewProps = {
    payOffBg1: any;
    payOffBg2: any;
    payOffBg3: any;
    payOff1:string;
    payOff2:string;
    payOff3:string;
  };
const imgNotFound = process.env.PUBLIC_URL + '/close-x.png';

const PayOffPreview: React.FC<PayOffPreviewProps> = ({payOffBg1, payOffBg2, payOffBg3, payOff1, payOff2, payOff3}) => {

    const {call, loading} = useApi();
    const {company} = useCompany();
    const animationControls = useAnimation();
    const [cartUpdated, setCartUpdated] = React.useState(false);
    
    const handleChange = () => {
        console.log('Im Changing')
    }
    console.log('logo_url', company?.logo_url)
    return (
        <>  
            <header className="w-full">
            <div className="bg-eBLUE p-2">
                <MaxWidthDiv className="flex flex-row justify-between items-center">
                    <h1 className="text-white font-bold cursor-pointer text-sm md:text-xl">Express Leather</h1>
                    <div className="text-white text-sm md:text-xl">
                        {company && company.company_name.toUpperCase()}
                    </div>
                    <div className="relative flex">
                        <motion.div
                            animate={{scale: [1, 1.5, 1]}}
                            transition={{duration: 0.3}}
                        >
                            <IoHomeOutline style={{width: "25px", height: "25px"}}
                                           className="mr-4 text-white text-xl cursor-pointer" >
                            </IoHomeOutline>
                        </motion.div>

                        <motion.div animate={animationControls}>
                            <IoCartOutline style={{width: "25px", height: "25px"}}
                                           className="text-white text-xl cursor-pointer" />
                            {cartUpdated && (
                                <motion.div
                                    className="absolute top-0 right-0 bg-red-500 rounded-full"
                                    style={{width: "10px", height: "10px"}}
                                    initial={{scale: 0}}
                                    animate={{scale: 1}}
                                    transition={{duration: 0.2}}
                                />
                            )}
                        </motion.div>
                    </div>
                </MaxWidthDiv>
            </div>

        </header>
                    
                    <div className="flex flex-col items-center justify-between px-4 py-2"
                         style={{backgroundColor: `${payOffBg1 ? payOffBg1 : '#add8e6'}`}}>
                        <MaxWidthDiv className='flex justify-between items-center w-full'>
                            <div className="flex items-center"
                                 style={{color: `${company && company.font_color ? company.font_color : '#fff'}`}}>
                                {/* <img style={{width: '50px'}} onError={e => {
                                    e.currentTarget.src = imgNotFound
                                }} src={`${company?.logo_url !== null && company?.logo_url}`}
                                     alt={`${company && company?.company_name}`}/> */}

                                 <img style={{width: '50px'}} onError={e => {
                                    e.currentTarget.src = imgNotFound
                                }} src={`https://www.net-evolution.com/wp-content/uploads/2021/01/netevolution-fav.jpg.png`}
                                     alt={`${company && company?.company_name}`}/>  

                            </div>
                            <div className="flex items-center ml-2 font-bold text-lg">
                                <h1 style={{color: `${company && company.font_color ? company.font_color : '#fff'}`}}>{payOff1}</h1>
                            </div>
                        </MaxWidthDiv>


                    </div>
                   
                        <div className={company && payOff2 ?"flex flex-row items-center justify-center px-4 py-2": "hidden"}
                             style={{backgroundColor: `${payOffBg2 ? payOffBg2 : '#add8e6'}`}}>

                            <MaxWidthDiv className=''>
                                <div className="flex flex-row justify-center ml-2 font-bold text-lg">
                                    <h1 style={{color: `${company && company.font_color ? company.font_color : '#fff'}`}}>
                                        {payOff2}</h1>
                                </div>
                            </MaxWidthDiv>
                        </div>
                    {/* {company && payOff3 ?
                        <div className="flex flex-row items-center justify-center px-4 py-2"
                             style={{backgroundColor: `${payOffBg3 ? payOffBg3 : '#add8e6'}`}}>

                            <MaxWidthDiv className=''>
                                        <div dangerouslySetInnerHTML={{__html: payOff3 && payOff3}}/>
                            </MaxWidthDiv>
                        </div> : ''} */}
        </>

    );
};

export default PayOffPreview;