import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {doPostRefreshToken} from "../../logic/auth";
import {useAuth} from "../contexts/auth-context";


function useApi() {

    const navigate = useNavigate();
    const {doRefreshToken} = useAuth();

    const [loading, setLoading] = useState(false);

    const call = async (
        func: (arg?: any) => Promise<any>,
        onSuccess: (response?: any) => void,
        onFailure?: (err?: any) => void) => {
        setLoading(true);

        let initial_response = await func();
        if (initial_response && initial_response.data) {
            const {data} = initial_response;
            if (data.status == 'ok') {
                if (data.data)
                    onSuccess(data.data);
            }

            if (data.status == 'ko') {
                if (data.error && data.error.message == 'Expired Token') {
                    let refresh_response = await doRefreshToken();
                    if (refresh_response) {
                        let initial_response = await func();
                        if (initial_response && initial_response.data) {
                            const {data} = initial_response;
                            if (data.status == 'ok') {
                                if (data.data)
                                    onSuccess(data.data);
                            }
                            if (data.status == 'ko') {
                                onFailure && onFailure(data.error ?? null);
                            }
                        }
                    }
                    navigate('/login');
                }
            }
        }

        setLoading(false);
    }

    return {
        loading,
        call,
    }
}

export default useApi;
