import React, {useEffect, useRef, useState} from 'react';
import Layout from "../components/ui/layout/Layout";
import useApi from "../utils/custom_hooks/api";
import {useCategory} from "../utils/contexts/categories-context";
import Category from "../components/Categories/Category";
import {doPostCategories} from '../logic/categories';
import {useToast} from '../utils/contexts/toast-context';
import {motion} from 'framer-motion';
import {FaPlus} from 'react-icons/fa';
import {useQuery} from 'react-query';
import {useQueryClient} from "react-query";
import {useCompany} from "../utils/contexts/company-context";
import {doGetWhoAmI} from "../logic/auth";
import {ICategory} from "../utils/interfaces/ICategory";
import {useAuth} from "../utils/contexts/auth-context";
import useApiGetCategories from "../utils/custom_hooks/useApiGetCategories";
import { ListManager } from 'react-beautiful-dnd-grid';

const CategoriesScreen = () => {
    const queryClient = useQueryClient()
    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 768;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);

    const {call, loading} = useApi();
    const {
        loadCategories,
        addCategory
    } = useCategory();
    const {displayMessage, displayError} = useToast();

    // todo: 2023/08/25, to be removed after full refactor
    const {initializeCompany} = useCompany();
    useEffect(() => {
        const load = async () => {
            if (!loading) {
                await call(
                    () => doGetWhoAmI(),
                    (res) => {
                        if (res.company) {
                            initializeCompany(res.company);
                        }
                    }
                )
            }

        }

        load();
    }, []);


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let name = '';
        let description = '';
        let image_url = '';
        const data = {
            name,
            description,
            image_url,
            weight: 0
        }

        await call(
            () => doPostCategories(data),
            (res) => {
                addCategory(res);
                displayMessage('Categoria aggiunta');
            }
        )
        await queryClient.invalidateQueries('getCategories')

    }

    const [isHovered, setIsHovered] = useState(false);

    const addButtonRef = useRef<HTMLButtonElement>(null);


    const handleResize = (entries: ResizeObserverEntry[]) => {
        const entry = entries[0];
        const {width} = entry.contentRect;

        if (width >= 102) {
            setIsHovered(true);
        } else {
            setIsHovered(false);
        }
    };
    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);

        if (addButtonRef.current) {
            resizeObserver.observe(addButtonRef.current);
        }

        return () => {
            if (addButtonRef.current) {
                resizeObserver.unobserve(addButtonRef.current);
            }
        };
    }, []);


    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 40,
        duration: 150,

    };

    const container = {
        hidden: {opacity: 0, scale: 1},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.1,
                staggerChildren: 0.05
            }
        },
        exit: {y: 20, opacity: 0}
    }

    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        },
        exit: {y: 20, opacity: 0}

    }


    useEffect(() => {
        const load = async () => {
            if (!loading) {
                await call(
                    () => doGetWhoAmI(),
                    (res) => {
                        if (res.company) {
                            initializeCompany(res.company);
                        }
                    }
                )
            }

        }

        load();
    }, []);


    const {data: categories} = useApiGetCategories()
    useEffect(() => {
        if (categories) {
            loadCategories(categories);
        }
    }, [categories]);

    const renderedCategories: any = [];
    
    {categories &&  (categories.forEach((category) => {
        {
            renderedCategories.push(category);
        }
    }))}
    return (
        <Layout title="Categories">
            <div className='h-[96vh] overflow-auto scrollbar-none pb-[50px]'
                 style={windowSize ? {paddingTop: 10} : {paddingTop: 35}}>
                <motion.button
                    initial={{borderRadius: 100, width: 50}} whileHover={{borderRadius: 5, width: 110}}
                    transition={spring} ref={addButtonRef} onClick={handleSubmit}
                    className="fixed bg-[#3782f1] text-[#fafcff] cursor-pointer bg-blur-[3px] bg-opacity-70 roundedn-full h-[50px] z-50
                                                   items-center justify-center p-1 flex items-center rounded-full hover:bg-opacity-100 bottom-[5.5%] right-[1.5%] origin-right backdrop-blur-[2px] backdrop-filter bg-opacity-20">

                    {/*<motion.div className="origin-right" animate={isHovered? {display:'flex', opacity:1}:{display:'none'}}>Aggiungi Colore</motion.div>*/}
                    {isHovered ? <div className='font-bold'>Crea Nuova</div> : <FaPlus className='scale-[1.5]'/>}
                </motion.button>

                <div className='flex flex-row justify-center w-full'>

                    <motion.div variants={container} initial="hidden" animate="visible"
                                className=" flex gap-[0.7rem] m-3 flex-wrap">
                        {categories && categories.map((category) =>
                            <Category category={category} key={category.id}/>
                        )}
                    </motion.div>
                </div>

            </div>
        </Layout>
    );
};

export default CategoriesScreen;