import { useRef } from "react";
import { useState } from "react";
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { MdOpacity } from "react-icons/md";
import { doPutArticle } from "../../logic/articles";
type Props = {
    condition: boolean,
    children: React.ReactNode,
    onUndo?: () => void,
}

const ConditionalOverlay = ({condition, children, onUndo}: Props) => {

    const over = document.getElementById('over');
    
   


    if (condition) {
        return (
            <AnimatePresence>
            <motion.div className="inset-0 bg-[gray] bg-opacity-[10%] flex flex-col items-center justify-center z-[100] fixed w-full h-full cursor"
             initial={{opacity:0}} animate={{opacity:100}} transition={{duration:0.05}}>
                {children}
            </motion.div></AnimatePresence>
        )
    } else
        return null;
};

export default ConditionalOverlay;