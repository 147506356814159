import React, {Fragment, useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";
import {useAuth} from "../../utils/contexts/auth-context";
import LoadingScreen from "../../pages/LoadingScreen";
import useApi from "../../utils/custom_hooks/api";

type Props = {
    children: React.ReactNode
}

const ProtectedRoute = ({children}: Props) => {

    const [loading, setLoading] = useState(true);

    const {isAuthenticated, loadAuthenticationData, setIsAuthenticated} = useAuth();
    const token = localStorage.getItem('@refresh_token');

    if (token) {
        setIsAuthenticated(true);
    }


    useEffect(() => {
        const load = async () => {
            await setLoading(true);
            await loadAuthenticationData();
            await setLoading(false);
        }

        load();
    }, [])


    return (
        <Fragment>
            {loading ?
                <LoadingScreen/>
                :
                isAuthenticated ?
                    <Fragment>
                        {children}
                    </Fragment>
                    :
                    <Navigate to="/login" replace/>

            }
        </Fragment>
    )

};

export default ProtectedRoute;
