import {API, ApiResponseBase} from "./api_config";
import {ICompany} from "../utils/interfaces/ICompany";

async function doPutCompany(data?: any) {
    return API.put(`/v1/backoffice/company`, data);
}

async function doPostCompanyLogo(data?: any) {
    return API.postUploader(`/v1/backoffice/company/upload_logo`, data);
}

export {
    doPutCompany,
    doPostCompanyLogo,
};