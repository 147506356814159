import {API} from "../api_config";

function doPostColor(article_id: number, data: any) {
    return API.postEncoded('/v1/backoffice/colors', data);
}

function doPutColor(id: string, params: any) {
    return API.put(`/v1/backoffice/colors/${id}`, params);
}

function doDeleteColor(id: number) {
    return API.DELETE(`/v1/backoffice/colors/${id}`);
}

export {
    doPostColor,
    doPutColor,
    doDeleteColor,
}