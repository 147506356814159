import React from 'react';
import Layout from "../components/ui/layout/Layout";

const HomeScreen = () => {
    return (
        <Layout>
            <div>Home</div>
        </Layout>
    );
};

export default HomeScreen;