import React from 'react';
import {useCompany} from "../../utils/contexts/company-context";
import {useArticle} from "../../utils/contexts/article-context";
import {useToast} from "../../utils/contexts/toast-context";
import useApi from "../../utils/custom_hooks/api";
import {doDeleteArticle} from "../../logic/articles";
import ModalButtonSection from "../ui/ModalButtonSection";
import {motion} from 'framer-motion';
import {useQueryClient} from 'react-query';
import {MdOutlineCancel} from 'react-icons/md'

type DeleteArticleModalProps = {
    formStyle: any;
    DeleteFormCloser: () => void;
};
const DeleteArticleModal: React.FC<DeleteArticleModalProps> = ({DeleteFormCloser, formStyle}) => {
    const queryClient = useQueryClient();
    const {selectedArticleId, removeArticle} = useCompany();
    const {closeDeleteArticleForm} = useArticle();
    const {call, loading} = useApi();
    const {displayMessage} = useToast();

    const handleClose = () => {
        DeleteFormCloser();
    }

    const handleConfirm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedArticleId) {
            await call(
                () => doDeleteArticle(selectedArticleId),
                (deleted_res) => {
                    if (deleted_res) {
                        removeArticle(deleted_res);
                        displayMessage('Articolo eliminato');
                        closeDeleteArticleForm();
                    }
                    queryClient.invalidateQueries('getArticles')
                }
            )
        }
    }

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 70,
        duration: 150,

    };

    return (

        <motion.div
            className="bg-[black] backdrop-blur-[4px] backdrop-filter bg-opacity-5 rounded p-4 flex justify-center items-center absolute z-[999]"
            style={formStyle} initial={{opacity: 0}} animate={{opacity: 1}} transition={spring}>
            <div></div>
            <div className="flex flex-row justify-center items-center w-full h-full">
                <ModalButtonSection loading={loading}
                                    loadingLabel='Cancellazione in corso'
                                    onUndo={DeleteFormCloser}
                                    onConfirm={handleConfirm}
                />
            </div>

        </motion.div>
    );
};

export default DeleteArticleModal;
