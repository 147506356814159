import React, {useEffect} from 'react';
import Layout from "../components/ui/layout/Layout";
import LoginForm from "../components/Login/LoginForm";
import {doGetWhoAmI} from "../logic/auth";
import useApi from "../utils/custom_hooks/api";
import {useToast} from '../utils/contexts/toast-context';

const LoginScreen = () => {

    const {call} = useApi();
    const {displayError} = useToast();
    useEffect(() => {
        const load = async () => {
            await call(
                () => doGetWhoAmI(),
                (res) => {
                    if (res) {
                        // console.log(res);
                    }
                },
            )
        }

        load();
    }, []);

    return (
        <Layout contentClassName="p-4">
            <div className="flex-1 flex flex-col items-center">
                <LoginForm/>
            </div>
        </Layout>
    );
};

export default LoginScreen;