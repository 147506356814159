import React from 'react';
import {Route, Routes, Router, BrowserRouter, BrowserRouterProps} from "react-router-dom";
import LoginScreen from "../../pages/LoginScreen";
import HomeScreen from "../../pages/HomeScreen";
import ProtectedRoute from "./ProtectedRoute";
import DashboardScreen from "../../pages/DashboardScreen";
import ArticlesScreen from "../../pages/ArticlesScreen";
import CategoriesScreen from "../../pages/CategoriesScreen";
import NavBar from '../ui/layout/Navigation/NavBar';
import MainProviders from './MainProviders';
import CompanyScreen from '../../pages/CompanyScreen';
const Navigation = () => {

    return (
        
        <Routes>
            
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/" element={
            <ProtectedRoute>
                <HomeScreen />
            </ProtectedRoute>
            } />
            <Route path="/articles" element={
                <ProtectedRoute>
                    <ArticlesScreen />
                </ProtectedRoute>
            } />
            <Route path="/categories" element={
                <ProtectedRoute>
                    <CategoriesScreen />
                </ProtectedRoute>
            } />
            <Route path="/company" element={
            <ProtectedRoute>
                <CompanyScreen />
            </ProtectedRoute>
            } />
        </Routes>  
       
    );
};

export default Navigation;