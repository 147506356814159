import React from 'react';
import { MenuButton } from '../../MenuButton';
import { useEffect, useState, useRef } from 'react';
import { AnimatePresence, motion, spring } from 'framer-motion';
import { NavLink, useLocation, matchPath } from "react-router-dom";
import { sidebarData } from './sidebarData';

type Props = {

    title?: string,
}



const NavBar = ({ title = "" }: Props) => {


    const [isOpen, setOpen] = React.useState(false);
    const closeNavBar = () => { setOpen(false) }

    const canvasStyle = {
        display: "flex",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center"
    };

    const menuButtonStyle = {
        marginLeft: "1rem"

    };

    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 768;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);


    const container = {
        hidden: { opacity: 0, scale: 1 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.2,
                staggerChildren: 0.05
            }
        },
        exit: { y: 20, opacity: 0 }
    }

    const items = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        },
        exit: { y: 20, opacity: 0 }

    }

    const spring = {
        type: "spring",
        stiffness: 600,
        damping: 60,
        
    };


    const [isHovering, setIsHovering] = useState(false);
    const hoverTimerRef = useRef<number | null>(null);

    const handleMouseEnter = () => {
        hoverTimerRef.current = window.setTimeout(() => {
            setIsHovering(true);
        }, 700);
    };

    const handleMouseLeave = () => {
        if (hoverTimerRef.current) {
            window.clearTimeout(hoverTimerRef.current);
        }
        setIsHovering(false);
    };

    if (windowSize == false) {
        return (

            <motion.ul variants={container} onHoverStart={handleMouseEnter} onHoverEnd={handleMouseLeave}
                initial='visible'
                animate={isHovering ? { width: 170 } : { width: 35 }} transition={{ duration: 0.15 }} className={"bg-eBLUE flex flex-col items-center z-[9999] absolute h-full"}>
                {sidebarData.map((item, index) => {
                    return (
                        <motion.li variants={items} key={index} className='flex flex-row w-full items-center h-[34px]'>
                            <NavLink to={item.path}
                                title={item.title}
                                className={({ isActive }) => isActive ? "items-center p-2 w-full text-[black] bg-white hover:bg-gray-600 flex flex-row gap-[10px] h-[35px] overflow-hidden" : "overflow-hidden h-[35px] items-center p-2 w-full flex flex-row text-white hover:bg-gray-600 gap-[10px]"}>
                                <div>{item.icons}</div>

                                {isHovering ? <motion.div initial={{ opacity: 0, scaleX: 0 }} animate={{ opacity: 1, scaleX: 1 }} transition={{ duration: 0.15 }} className='origin-left scale[0.8] m-0 p-0'>{item.title}</motion.div> : ''}

                            </NavLink>
                        </motion.li>
                    )
                })}
            </motion.ul>
        )
    } else {
        return (

            <div className=''>
                <div className="bg-eBLUE h-[50px] flex flex-row items-center fixed top-0 z-[200] w-full justify-between" >
                    <div className='scale-[0.9]'><MenuButton
                        isOpen={isOpen}
                        onClick={() => setOpen(!isOpen)}
                        strokeWidth="6"
                        color="white"
                        lineProps={{ strokeLinecap: "round" }}
                        transition={{ type: "spring", stiffness: 260, damping: 20 }}
                        width="32"
                        height="24"
                        style={menuButtonStyle}
                    /></div>

                    <div className="flex flex-row items-center items-baseline gap-2 whitespace-nowrap text-[15px]   ">
                        <div className="text-white font-bold w-1/2">BACKOFFICE - Express Leather</div>
                        <div className="text-white">{title}</div>
                    </div>
                </div>

                <motion.div className='w-[100%]  z-[99] h-screen absolute' onClick={closeNavBar } initial={{ x: '-100%' }} animate={isOpen ? { x: 0, visibility: 'visible' } : { x: '-102%', }} transition={spring}>
                <motion.ul variants={container}
                    className={"bg-eBLUE flex flex-col w-[100%] items-center z-[100] top-[50px] h-screen max-w-[65%] fixed"}>
                    {sidebarData.map((item, index) => {
                        return (
                            <motion.li variants={items} key={index} className='flex flex-col w-full items-center' >
                                <NavLink to={item.path} onClick={closeNavBar}
                                    title={item.title}
                                    className={({ isActive }) => isActive ? "items-center p-2 w-full text-[black] bg-white hover:bg-gray-600 flex flex-row gap-3" : "gap-3 items-center p-2 w-full text-[white] bg-eBLUE hover:bg-gray-600 flex flex-row"}>
                                    {item.icons}
                                    {item.title}
                                </NavLink>
                            </motion.li>
                        )
                    })}
                </motion.ul></motion.div>
            </div>





        )
    }
};

export default NavBar;