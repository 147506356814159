import React from 'react';
import {FaSpinner} from "react-icons/fa";

type Props = {
    label?: string,
    className?: string,
}

const Loading = ({label='Caricamento', className = ''}: Props) => {
    return (
        <div className={`flex flex-row items-center justify-center ${className}`}>
            <div>{label}</div>
            <div className={`flex flex-row items-center justify-center ${label != '' ? 'ml-1' : ''}`}>
                <FaSpinner className="animate-spin" />
            </div>
        </div>
    );
};

export default Loading;