import {useQuery, UseQueryResult} from "react-query";
import {IArticle} from "../interfaces/IArticle";
import {API, CustomAxiosError} from "../../logic/api_config";

async function doGetArticles(): Promise<IArticle[]> {
    try {
        const response = await API.get(`/v1/backoffice/articles`);
        return response?.data.data;
    } catch (error) {
        console.log("error in useApiGetArticles hook", error)
        throw error;
    }
}

const useApiGetArticles = (): UseQueryResult<IArticle[], CustomAxiosError> => {
    return useQuery<IArticle[], CustomAxiosError>("getArticles", () => doGetArticles());
};

export default useApiGetArticles;