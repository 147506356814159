import {API} from "./api_config";
import axios from "axios";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

async function doPostLogin(user:string, password: string) {
    return API.post('/v1/login', {email: user, password});
}

async function doPostRefreshToken(refresh_token: string) {
    return axios.post(ENDPOINT + '/v1/token/refresh', {refresh_token});
}

async function doGetWhoAmI() {
    return API.get('/v1/users/whoami');
}

export {
    doPostLogin,
    doPostRefreshToken,
    doGetWhoAmI
}