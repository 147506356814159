import React, {useState} from "react";
import category from "../../components/Categories/Category";
import {ICategory} from "../interfaces/ICategory";

type CategoryContextType = {
    categories?: any[],

    selectedCategoryId: string | number | undefined,
    selectCategoryById: (id: string | number) => void,
    getSelectedCategory: () => any,

    loadCategories: (categories: ICategory[]) => void,
    addCategory: (category: any) => void,
    removeCategory: (category: any) => void,
    updateCategory: (category: any) => void,

    createCategoryModalStatus: boolean,
    editCategoryModalStatus: boolean,
    deleteCategoryModalStatus: boolean,
    openCreateCategoryModal: () => void,
    openEditCategoryModal: () => void,
    openDeleteCategoryModal: () => void,
    closeCreateCategoryModal: () => void,
    closeEditCategoryModal: () => void,
    closeDeleteCategoryModal: () => void,
}

const CategoryContext = React.createContext<CategoryContextType | undefined>(undefined);

function useCategoryContextType() {
    const [categories, setCategories] = useState<any[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | number | undefined>(undefined);

    const selectCategoryById = (id: string | number) => setSelectedCategoryId(id);

    const getSelectedCategory = () => {
        if (selectedCategoryId) {
            const _category = categories.find(category => category.id == selectedCategoryId);
            return _category;
        } else
            return null;
    }

    const [createCategoryModalStatus, setCreateCategoryModalStatus] = useState(false);
    const [editCategoryModalStatus, setEditCategoryModalStatus] = useState(false);
    const [deleteCategoryModalStatus, setDeleteCategoryModalStatus] = useState(false);

    const openCreateCategoryModal = () => setCreateCategoryModalStatus(true);
    const openEditCategoryModal = () => setEditCategoryModalStatus(true);
    const openDeleteCategoryModal = () => setDeleteCategoryModalStatus(true);

    const closeCreateCategoryModal = () => setCreateCategoryModalStatus(false);
    const closeEditCategoryModal = () => setEditCategoryModalStatus(false);
    const closeDeleteCategoryModal = () => setDeleteCategoryModalStatus(false);

    const addCategory = (category: any) => {
        setCategories([...categories, {...category}]);
    }

    const removeCategory = (category: any) => {
        const _categories = categories.filter((cat) => cat.id != category.id);
        setCategories([..._categories]);
    }

    const updateCategory = (category: any) => {
        const _categories = categories.map((cat) => {
            if (cat.id == category.id) return {...category};
            else return {...cat};
        })

        setCategories([..._categories]);
    }


    const loadCategories = (cat: ICategory[]) => {
        setCategories(cat);
    };

    return {
        categories, loadCategories, addCategory, removeCategory, updateCategory,
        selectedCategoryId, selectCategoryById,
        createCategoryModalStatus, editCategoryModalStatus, deleteCategoryModalStatus,
        openCreateCategoryModal, openEditCategoryModal, openDeleteCategoryModal,
        closeCreateCategoryModal, closeEditCategoryModal, closeDeleteCategoryModal,
        getSelectedCategory
    }
}

function CategoryProvider({children}: { children: React.ReactNode }) {
    const value = useCategoryContextType();
    return <CategoryContext.Provider value={value}>{children}</CategoryContext.Provider>;
}

function useCategory() {
    const context = React.useContext(CategoryContext);
    if (!context) {
        throw new Error('useCategory must be used within a CategoryProvider');
    }
    return context;
}

export {
    useCategory,
    CategoryProvider
}
