import React, {useEffect, useState, useRef, useLayoutEffect} from 'react';
import useApi from '../../utils/custom_hooks/api';
import {useCompany} from '../../utils/contexts/company-context';
import Loading from '../ui/Loading';
import {useToast} from '../../utils/contexts/toast-context';
import {doGetWhoAmI} from '../../logic/auth';
import {ChromePicker} from 'react-color';
import {AnimatePresence, motion} from 'framer-motion';
import {FaSave} from 'react-icons/fa';
import {IoReloadOutline} from 'react-icons/io5';

type PayOffRowProps = {
    payOffColor: any | null;
    payOffName: any | null;
    title: any | null;
    onUpdate: () => void;
    bgUpdater: (color: string) => void;
    inputUpdater: (input: string) => void;
};

const PayOffRow: React.FC<PayOffRowProps> = ({payOffColor, payOffName, title, onUpdate, bgUpdater, inputUpdater}) => {


    const colorButton = React.useRef<HTMLDivElement>(null);
    const handleSs = () => {
        onUpdate && onUpdate();
        setIsChanging(false);
        setOldValues();
    }
    const [oldName, setOldName] = useState<string>('');
    const [oldColor, setOldColor] = useState<string>('');


    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 640;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);


    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 40,
    };


    const [isChanging, setIsChanging] = useState(false)
    const handleChange = (e: any) => {
        if (payOffColor.current) {
            setColors({hex: payOffColor.current.value, rgb: ''});
            bgUpdater(payOffColor.current.value);
            inputUpdater(payOffName.current.value)
        }
        setIsChanging(true)
    }

    const setOldValues = () => {
        if (payOffColor.current) {
            setOldColor(payOffColor.current.value)
        }
        if (payOffName.current) {
            setOldName(payOffName.current.value)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setOldValues();
        }, 100);
    }, [])


    const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);
    const colorPickRef = useRef<HTMLDivElement>(null);


    const handleClickOutside = (event: MouseEvent) => {
        if (colorPickRef.current && !colorPickRef.current.contains(event.target as Node)) {
            setIsPickerOpen(false);
        }
    }

    useEffect(() => {

        if (isPickerOpen) {
            setTimeout(function () {
                document.addEventListener('click', handleClickOutside);
            }, 50)
        } else {
            setTimeout(function () {
                document.removeEventListener('click', handleClickOutside);
            }, 50)
        }
        return () => {
            setTimeout(function () {
                document.removeEventListener('click', handleClickOutside);
            }, 50)
        };
    }, [isPickerOpen]);


    const [colors, setColors] = useState<any | null>(null);

    const colorPicker = (e: any) => {
        const newColor = {
            hex: e.hex,
            rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")"
        };
        setColors(newColor);
        setIsChanging(true);
        bgUpdater(colors.hex);
        payOffColor.current.value = newColor.hex;
    };


    const [pickerStyle, setPickerStyle] = useState<React.CSSProperties>({});

    const handleColorButtonClick = (colorButton: React.RefObject<HTMLDivElement>) => {
        setIsPickerOpen(true);
        if (colorButton.current) {
            const {left, top} = colorButton.current.getBoundingClientRect();

            const left2 = left;
            const newPickerStyle: React.CSSProperties = {
                position: `absolute`,
                left: `${left2}}px`,
                top: `${top + 1}px`,
                zIndex: '100',
            };
            setPickerStyle(newPickerStyle);
        }
    };

    useLayoutEffect(() => {
        setTimeout(function () {
            if (colorButton.current) {
                const {left, top} = colorButton.current.getBoundingClientRect();
                const left2 = left;
                const newPickerStyle: React.CSSProperties = {
                    position: `absolute`,
                    left: `${left2}px`,
                    top: `${top}px`,
                    zIndex: '100',
                };
                setPickerStyle(newPickerStyle);
            }
        })
    }, [colorButton.current]);


    useEffect(() => {

        if (payOffColor) {
            setColors({hex: payOffColor.current.value, rgb: ''});
        }
    }, [payOffColor.current]);


    const [isClicked, setIsClicked] = useState(false);

    const handleOnClick = () => {
        setIsClicked(true);
        setTimeout(() => {
            setIsClicked(false);
        }, 0.5);
        resetFormValues();

    };

    const resetFormValues = () => {
        setIsChanging(false);
        payOffColor.current.value = oldColor;
        payOffName.current.value = oldName;

        setColors({hex: payOffColor.current.value, rgb: ''});
        bgUpdater(oldColor);
        inputUpdater(payOffName.current.value)

    }
    if (windowSize == true) {
        return (


            <div className='flex flex-col  items-center pb-[10px] w-full px-[20px] z-[300]'>

                <div title={title} className='px-0 py-1 flex flex-col gap-[5px] my-[2px] w-full'>
                    <div className='font-semibold'>{title}</div>
                    <input
                        className="cursor-default focus:cursor-text border-[1px] border-[lightgray] rounded-[5px] px-[5px] w-full"
                        placeholder={'Aggiungi ' + title}
                        name="name"
                        ref={payOffName}
                        onChange={handleChange}
                    />


                </div>

                <div className='flex flex-row gap-2 justify-between w-full'>

                    <div ref={colorButton}
                         className='w-[25px] h-[25px] rounded-[5px] border-[1px] border-[lightgray] cursor-pointer'
                         style={colors ? {backgroundColor: (colors.hex)} : {}}
                         onClick={() => handleColorButtonClick(colorButton)}></div>

                    <input
                        className="cursor-default focus:cursor-text border-[1px] border-[lightgray] rounded-[5px] px-[5px] w-[100px]"
                        placeholder={'#HEX'}
                        name="name"
                        ref={payOffColor}
                        onChange={handleChange}
                    />

                    {isChanging ?
                        <div
                            className="cursor-pointer h-6 w-6 flex justify-center items-center hover:text-[green] rounded-[6px] duration-[150ms] active:scale-[0.9] scale-[0.95]"
                            onClick={handleSs}>
                            <FaSave title="Salva" className="h-5 w-5"/></div>
                        :
                        <div
                            className="h-6 w-6 flex justify-center items-center  rounded-[6px] opacity-0 duration-[300ms] scale-[0.95]">
                            <FaSave title="Salva" className="h-5 w-5"/></div>}

                </div>


                <motion.div ref={colorPickRef} style={pickerStyle} initial={{scale: 0}}
                            animate={isPickerOpen ? {scale: 0.8, opacity: 1, y: +32} : {opacity: 0, y: +32}}
                            exit={{scale: 0}} transition={spring} className='origin-top-left'>
                    <ChromePicker
                        color={colors !== null && colors.hex}
                        onChange={e => colorPicker(e)}
                        disableAlpha

                    />
                </motion.div>

            </div>


        );
    } else {
        return (


            <div className='flex flex-row justify-between items-center w-full'>
                <div title={title} className='px-0 py-1 flex flex-row gap-[30px] my-[2px]'>
                    <div className='font-semibold min-w-[65px]'>{title}</div>
                    {payOffName ? <input
                        className="cursor-default focus:cursor-text border-[1px] border-[lightgray] rounded-[5px] px-[5px] min-w-[300px]"
                        placeholder={'Aggiungi ' + title}
                        name="name"
                        ref={payOffName}
                        onChange={handleChange}
                    /> : ''}
                </div>
                <div className='flex flex-row gap-2 items-center'>
                    
                    <div ref={colorButton}
                         className='w-[25px] h-[25px] rounded-[5px] border-[1px] border-[lightgray] cursor-pointer'
                         style={colors ? {backgroundColor: (colors.hex)} : {}}
                         onClick={() => handleColorButtonClick(colorButton)}
                         onChange={() => bgUpdater(colors.hex)}></div>
                    <input
                        className="cursor-default focus:cursor-text border-[1px] border-[lightgray] rounded-[5px] px-[5px] w-[100px]"
                        placeholder={'#HEX'}
                        name="name"
                        ref={payOffColor}
                        onChange={handleChange}
                    />
                    
                    <div className='scale-[1.5] text-[gray] cursor-pointer mr-[5px]'>
                        <motion.div onClick={handleOnClick}
                                    animate={isChanging ? {rotate: 0, opacity: 1} : {rotate: -90, opacity: 0}}>
                            <IoReloadOutline className='-scale-x-100'/></motion.div>
                    </div>
                    {isChanging ?
                        <div
                            className="cursor-pointer h-6 w-6 flex justify-center items-center hover:text-[green] rounded-[6px] duration-[150ms] active:scale-[0.9] scale-[0.95]"
                            onClick={handleSs}><FaSave
                            title="Salva"
                            className="h-5 w-5"/></div> : <div
                            className="h-6 w-6 flex justify-center items-center  rounded-[6px] opacity-0 duration-[300ms] scale-[0.95]">
                            <FaSave
                                title="Salva"
                                className="h-5 w-5"/></div>}
                </div>


                <motion.div ref={colorPickRef} style={pickerStyle} initial={{scale: 0}}
                            animate={isPickerOpen ? {scale: 1, opacity: 1, x: -266} : {opacity: 0, x: -266}}
                            exit={{scale: 0}} transition={spring} className='origin-top-right'>
                    <ChromePicker
                        color={colors !== null && colors.hex}
                        onChange={e => colorPicker(e)}
                        disableAlpha

                    />
                </motion.div>
                
 
            </div>


        );
    }

};

export default PayOffRow;