import React from 'react';
import Layout from "../components/ui/layout/Layout";
import Loading from "../components/ui/Loading";

const LoadingScreen = () => {
    return (
        <Layout>
            <Loading />
        </Layout>
    );
};

export default LoadingScreen;