import React, {useState} from "react";

type ArticleContextType = {
    createArticleFormStatus: boolean,
    openCreateArticleForm: () => void,
    closeCreateArticleForm: () => void,

    editArticleFormStatus: boolean,
    openEditArticleForm: () => void,
    closeEditArticleForm: () => void,

    deleteArticleFormStatus: boolean,
    openDeleteArticleForm: () => void,
    closeDeleteArticleForm: () => void,

    isCreateArticleButtonOpen: boolean,
    openCreateArticleButton: (isCreateArticleButtonOpen:boolean) => void,

}

const ArticleContext = React.createContext<ArticleContextType | undefined>(undefined);

function useArticleContextValue() {
    const [createArticleFormStatus, setCreateArticleFormStatus] = useState(false);
    const [editArticleFormStatus, setEditFormStatus] = useState(false);
    const [deleteArticleFormStatus, setDeleteArticleFormStatus] = useState(false);
    const [isCreateArticleButtonOpen, setIsCreateArticleButtonOpen] = useState<boolean>(true);

    const openCreateArticleForm = () => setCreateArticleFormStatus(true);
    const closeCreateArticleForm = () => setCreateArticleFormStatus(false);

    const openEditArticleForm = () => setEditFormStatus(true);
    const closeEditArticleForm = () => setEditFormStatus(false);

    const openDeleteArticleForm = () => setDeleteArticleFormStatus(true);
    const closeDeleteArticleForm = () => setDeleteArticleFormStatus(false);

    const openCreateArticleButton = (button: boolean) => {
        setIsCreateArticleButtonOpen(button);
    }

    return {
        createArticleFormStatus, openCreateArticleForm, closeCreateArticleForm,
        editArticleFormStatus, openEditArticleForm, closeEditArticleForm,
        deleteArticleFormStatus, openDeleteArticleForm, closeDeleteArticleForm, isCreateArticleButtonOpen, openCreateArticleButton,
    }
}

function ArticleProvider({children}: {children: React.ReactNode}) {
    const value = useArticleContextValue();
    return <ArticleContext.Provider value={value}>{children}</ArticleContext.Provider>;
}

function useArticle() {
    const context = React.useContext(ArticleContext);
    if(!context) {
        throw new Error("useArticle must be used within an ArticleProvider");
    }
    return context;
}

export {
    ArticleProvider,
    useArticle,
}