import React from 'react';
import {ProvideAuth} from "../../utils/contexts/auth-context";
import {CompanyProvider} from "../../utils/contexts/company-context";
import {ArticleProvider} from "../../utils/contexts/article-context";
import {ColorProvider} from "../../utils/contexts/color-context";
import {ToastProvider} from "../../utils/contexts/toast-context";
import {CategoryProvider} from "../../utils/contexts/categories-context";

type Props = {
    children: React.ReactNode;
}

const MainProviders = ({children}: Props) => {
    return (
        <ProvideAuth>
            <ToastProvider>
                <CategoryProvider>
                    <CompanyProvider>
                        <ArticleProvider>
                            <ColorProvider>
                                {children}
                            </ColorProvider>
                        </ArticleProvider>
                    </CompanyProvider>
                </CategoryProvider>
            </ToastProvider>
        </ProvideAuth>
    );
};

export default MainProviders;