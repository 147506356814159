import React, {useEffect, useState} from 'react';
import ModalPanel from "../ui/ModalPanel";
import {useColor} from "../../utils/contexts/color-context";
import {useCompany} from "../../utils/contexts/company-context";
import useApi from "../../utils/custom_hooks/api";
import {doPutColor} from "../../logic/__mocks__/colors";
import {useToast} from "../../utils/contexts/toast-context";
import ModalButtonSection from "../ui/ModalButtonSection";
import ReactCountryFlag from "react-country-flag"
import {doPutTranslation} from '../../logic/translations';
import {motion} from 'framer-motion';
import {useQueryClient} from 'react-query';

type EditProps = {
    
    EditFormCloser: () => void;
    colors:any;
};

const EditColorForm: React.FC<EditProps> = ({EditFormCloser, colors}) => {

    const {closeEditColorForm, getSelectedColorFromArticle, selectedColorId, } = useColor();
    const {selectedArticle, selectedArticleId, updateColor, selectedLang, selectLang, checkSameName, loadColors} = useCompany();

    

    const {call, loading} = useApi();
    const {displayMessage, displayError} = useToast();

    const [currentColor, setCurrentColor] = useState(getSelectedColorFromArticle(selectedArticle()));

    const nameRefIta = React.useRef<HTMLInputElement>(null);
    const nameRefEn = React.useRef<HTMLInputElement>(null);
    const descriptionRefIta = React.useRef<HTMLTextAreaElement>(null);
    const descriptionRefEn = React.useRef<HTMLTextAreaElement>(null);
    
    const handleUndo = () => {
        EditFormCloser();
        closeEditColorForm();
    }
    const queryClient = useQueryClient();
    const handleConfirm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (currentColor) {

            let color = currentColor

            if (nameRefEn.current && nameRefEn.current.value != (currentColor?.['translations']?.[2]?.['text']) && checkSameName(colors, 2, nameRefEn, color) == false) {
                const name = nameRefEn.current.value;
                const data = {
                    name
                }
                await call(
                    () => doPutTranslation('colors', color.id, 'name', 'en', data),
                    (res) => {
                        if (res && selectedArticleId) {                     
                        EditFormCloser();
                        }
                        displayMessage('Color edited')
                        },
                    (err) => {
                    }
                )
            }else{if(checkSameName(colors, 2, nameRefEn, color) == true){displayError('Nome Inglese già usato')}}

            if (nameRefIta.current && nameRefIta.current.value != (currentColor?.['translations']?.[3]?.['text']) && checkSameName(colors, 3, nameRefIta, color) == false ) {
                const name = nameRefIta.current.value;
                const data = {
                    name
                }
                await call(
                    () => doPutTranslation('colors', color.id, 'name', 'it', data),
                    (res) => {
                        if (res && selectedArticleId) {                     
                        EditFormCloser();
                        }
                        displayMessage('Color edited')
                        },
                    (err) => {
                    }
                )
            }else{if(checkSameName(colors, 3, nameRefIta, color) == true){displayError('Nome Italiano già usato')}}

            if (descriptionRefEn.current && descriptionRefEn.current.value != (currentColor?.['translations']?.[0]?.['text'])) {
                const description = descriptionRefEn.current.value;
                const data = {
                    description
                }
                await call(
                    () => doPutTranslation('colors', color.id, 'description', 'en', data),
                    (res) => {
                        if (res && selectedArticleId) {                     
                        EditFormCloser();
                        }
                        displayMessage('Color edited')
                        },
                    (err) => {

                    }
                )
            }

            if (descriptionRefIta.current && descriptionRefIta.current.value != (currentColor?.['translations']?.[1]?.['text'])) {
                const description = descriptionRefIta.current.value;
                const data = {
                    description
                }
                await call(
                    () => doPutTranslation('colors', color.id, 'description', 'it', data),
                    (res) => {
                         if (res && selectedArticleId) {                     
                         EditFormCloser();
                         }
                         displayMessage('Color edited')
                         },
                    (err) => {
                    }
                )

            }


            // const data = {}

            // await call(
            //     () => doPutColor(color.id, data),
            //     (res) => {
            //         if (res && selectedArticleId) {
            //             updateColor(selectedArticleId, res);
            //             closeEditColorForm();
            //             EditFormCloser();
            //         }
            //         displayMessage('Color edited')
            //     },
            //     (error) => {
            //         console.log(error);
            //         displayError("error editing color");
            //     }
            // )
            queryClient.invalidateQueries('getArticles')
        }
    }

    const setFormValues = () => {
        if (nameRefIta.current && descriptionRefIta.current
        ) {
            nameRefIta.current.value = (currentColor?.['translations']?.[3]?.['text'])
            descriptionRefIta.current.value = (currentColor?.['translations']?.[1]?.['text'])
        }
        if (nameRefEn.current
            && descriptionRefEn.current) {
            nameRefEn.current.value = (currentColor?.['translations']?.[2]?.['text'])
            descriptionRefEn.current.value = (currentColor?.['translations']?.[0]?.['text'])
        }
    }
    
    const handleChange = (e: any) => {

    }

    useEffect(() => {
        setCurrentColor(getSelectedColorFromArticle(selectedArticle()))
    }, [selectedColorId])

    useEffect(() => {
        setFormValues();
    }, []);

    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 450;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);


    return (
        <ModalPanel>
            {windowSize ?
                <div className='flex flex-col gap-[15px] pt-2 px-2 items-center'>

                    <div className='w-full h-auto'>
                        <div className='flex flex-row items-center w-full justify-between'>
                            <div className="">{
                                currentColor.translations.map((obj: any, index: any) => {
                                    if (obj.lang === selectedLang)
                                        return (obj.field == 'name') ? (

                                            <p title="Nome" key={index + 1}
                                               className='px-1 py-1 flex flex-row gap-[10px]'>


                                                <input
                                                    className="border-[1px] border-[lightgray] w-full rounded-[5px] px-[5px]"
                                                    placeholder={(obj.lang === 'en') ? 'Name' : 'Nome'}
                                                    name="name"
                                                    ref={(obj.lang === 'en') ? nameRefEn : nameRefIta}
                                                    onChange={handleChange}
                                                />
                                            </p>
                                        ) : ''

                                })

                            }</div>

                            <div className='flex flex-row gap-[10px] px-[7px]'>
                                <ReactCountryFlag countryCode={'it'}
                                                  style={{width: '2em', minWidth: '2em', height: 'auto'}} svg
                                                  className={(selectedLang === 'it') ? 'opacity-1 duration-[150ms]' : 'cursor-pointer opacity-[0.3] duration-[150ms]'}
                                                  onClick={() => selectLang('it')}/>
                                <ReactCountryFlag countryCode={'gb'}
                                                  style={{width: '2em', minWidth: '2em', height: 'auto'}} svg
                                                  className={(selectedLang === 'en') ? 'opacity-1 duration-[150ms]' : 'cursor-pointer opacity-[0.3] duration-[150ms]'}
                                                  onClick={() => selectLang('en')}/>
                            </div>
                        </div>

                        <div className="">{
                            currentColor.translations.map((obj: any, index: any) => {
                                if (obj.lang === selectedLang)
                                    return (obj.field === 'description') ? (

                                        <p title="descrizione" key={index + 1}
                                           className='px-1 py-1 flex flex-row items-center gap-[20px]'>
                                    
                                     <textarea
                                         className="w-full bg-[white] border-[1px] border-[lightgray] shadow-[lightgray] shadow-inner rounded-[5px] p-[3px]"
                                         placeholder={(obj.lang === 'en') ? 'Description' : 'Descrizione'}
                                         name="description"
                                         ref={(obj.lang === 'en') ? descriptionRefEn : descriptionRefIta}
                                         onChange={handleChange}
                                     />
                                        </p>
                                    ) : ''
                            })
                        }
                        </div>
                    </div>


                    <div className='absolute bottom-0 my-4 w-[90%] text-white'><ModalButtonSection loading={loading}
                                                                                                   onUndo={handleUndo}
                                                                                                   onConfirm={handleConfirm}
                                                                                                   loadingLabel='Modifica in corso'
                                                                                                   confirmLabel='Modifica'/>
                    </div>
                </div>


                :

                <motion.div className=''>
                    <div className='text-[25px] font-semibold mx-1 text-white'>Modifica colore</div>
                    <form className="flex flex-col m-[10px]" onSubmit={handleConfirm}>

                        <div key={currentColor.id} className="rounded overflow-hidden">
                            <div
                                className='flex flex-row text-[20px] font-semibold mx-1 text-white justify-start gap-[10px]'>
                                <ReactCountryFlag countryCode={'it'} style={{width: '1.5em', height: '1.5em',}} svg/>Italiano
                            </div>
                            {
                                currentColor.translations.map((obj: any, index: any) => {
                                    if (obj.lang == 'it')
                                        return (obj.field == 'name') ? (

                                            <p title="Nome" key={index + 1}
                                               className='px-0 py-1 flex flex-row gap-[10px]'>

                                                {/*<ReactCountryFlag countryCode={(obj.lang === 'en') ? 'gb' : obj.lang} style={{ width: '2em', height: '2em', }} svg />*/}
                                                <input
                                                    className="focus:outline-none cursor-default focus:cursor-text border-[1px] border-[lightgray] rounded-[5px] px-[5px]"
                                                    placeholder={(obj.lang === 'en') ? 'Name' : 'Nome'}
                                                    name="name"
                                                    ref={(obj.lang === 'en') ? nameRefEn : nameRefIta}
                                                    onChange={handleChange}
                                                />
                                            </p>
                                        ) : ''

                                })

                            }
                            {
                                currentColor.translations.map((obj: any, index: any) => {
                                    if (obj.lang == 'it')
                                        return (obj.field == 'description') ? (

                                            <p title="descrizione" key={index + 1}
                                               className='px-0 py-1 flex flex-row items-center gap-[20px]'>

                                            <textarea
                                                className="focus:outline-none cursor-default focus:cursor-text w-[95%] bg-[white] border-[1px] border-[lightgray] shadow-[lightgray] shadow-inner rounded-[5px] p-[3px]"
                                                placeholder={(obj.lang === 'en') ? 'Description' : 'Descrizione'}
                                                name="description"
                                                ref={(obj.lang === 'en') ? descriptionRefEn : descriptionRefIta}
                                                onChange={handleChange}
                                            />
                                            </p>
                                        ) : ''
                                })
                            }


                            <div className="py-4">
                                <div className='flex flex-row text-[20px] font-semibold mx-1 text-white'>
                                    <ReactCountryFlag countryCode={'gb'} style={{width: '1.5em', height: '1.5em',}}
                                                      svg/>Inglese
                                </div>
                                {
                                    currentColor.translations.map((obj: any, index: any) => {
                                        if (obj.lang == 'en')
                                            return (obj.field == 'name') ? (

                                                <p title="Nome" key={index + 1}
                                                   className='px-0 py-1 flex flex-row gap-[10px]'>

                                                    {/*<ReactCountryFlag countryCode={(obj.lang === 'en') ? 'gb' : obj.lang} style={{ width: '2em', height: '2em', }} svg />*/}
                                                    <input
                                                        className="focus:outline-none cursor-default focus:cursor-text border-[1px] border-[lightgray] rounded-[5px] px-[5px]"
                                                        placeholder={(obj.lang === 'en') ? 'Name' : 'Nome'}
                                                        name="name"
                                                        ref={(obj.lang === 'en') ? nameRefEn : nameRefIta}
                                                        onChange={handleChange}
                                                    />
                                                </p>
                                            ) : ''

                                    })

                                }
                                <div className="">{
                                    currentColor.translations.map((obj: any, index: any) => {
                                        if (obj.lang == 'en')
                                            return (obj.field == 'description') ? (

                                                <p title="descrizione" key={index + 1}
                                                   className='px-0 py-1 flex flex-row items-center'>

                                                <textarea
                                                    className="focus:outline-none cursor-default focus:cursor-text w-[95%] bg-[white] border-[1px] border-[lightgray] shadow-[lightgray] shadow-inner rounded-[5px] p-[3px]"
                                                    placeholder={(obj.lang === 'en') ? 'Description' : 'Descrizione'}
                                                    name="description"
                                                    ref={(obj.lang === 'en') ? descriptionRefEn : descriptionRefIta}
                                                    onChange={handleChange}
                                                />
                                                </p>
                                            ) : ''
                                    })
                                }
                                </div>
                            </div>
                        </div>
                        
                        <div className='my-4 w-[100%] text-white mt-[50px]'><ModalButtonSection loading={loading}
                                                                                                onUndo={handleUndo}
                                                                                                onConfirm={handleConfirm}
                                                                                                loadingLabel='Modifica in corso'
                                                                                                confirmLabel='Modifica'/>
                        </div>
                    </form>
                </motion.div>}
        </ModalPanel>
        

    );
};

export default EditColorForm;