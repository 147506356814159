import React, {useState} from 'react';
import ArticleRow from './ArticleRow';
import { motion } from 'framer-motion';
import { doPutTranslation } from '../../logic/translations';

interface DashButtonProps{
    isActive:boolean,
    onChange: (e: any) => void,

}

function DashButton({isActive, onChange} : DashButtonProps){
    
    const [status, setStatus] = useState(false);
    function click(){ setStatus(!status)};

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 45, 
        duration: 0.1,
        
    };
    const DashClass = 'cursor-pointer w-[36px] h-[18px] rounded-full flex items-center outline outline-[3px] outline-[lightgray] duration-[150ms] z-[50] relative';
    const DashClass2 = isActive ? "bg-[green]" : "bg-[darkred]";


    return(
        <motion.label
        className={`${DashClass} ${DashClass2}`} >
            
        <motion.div
            transition={spring} animate={isActive ? {right:-16} : {right:0}}
            className="w-[20px] h-[20px] bg-[white] rounded-full relative shadow-lg shadow-black-500/40 z-[50]"
        ></motion.div>
        <input type="checkbox" name='is_active' checked={isActive} onChange={onChange} style={{ display: 'none' }} />
    </motion.label>
    );
};
export default DashButton;