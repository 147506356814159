import axios from "axios";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
let refreshPromise = null;


const response_interceptor = (instance) => {
    let isRefreshing = false;
    instance.interceptors.response.use(resp => resp, async error => {

        if (error) {
            console.log("Interceptor Error", error)
        }
        const originalRequest = error.config;
        if (error.response.status === 401 && error.response.data.code === 'Expired Token' && !isRefreshing) {
            originalRequest._retry = true;
            isRefreshing = true;
            const response = await axios.post(ENDPOINT + '/api/token/refresh/', {
                refresh: localStorage.getItem('@refresh_token')
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }, {withCredentials: true});

            if (response.status === 200) {
                localStorage.setItem("@access_token", response.data.access);
                instance.defaults.headers.common[
                    "Authorization"
                    ] = `Bearer ${response.data.access}`;
                originalRequest.headers[
                    "Authorization"
                    ] = `Bearer ${response.data.access}`;
                return instance(originalRequest);
            }
        }
        isRefreshing = false;
        return Promise.reject(error);
    });
}

export default response_interceptor;