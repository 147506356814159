import React from 'react';
import ModalPanel from "../ui/ModalPanel";
import {useCategory} from "../../utils/contexts/categories-context";
import useApi from "../../utils/custom_hooks/api";
import {doDeleteCategories} from "../../logic/categories";
import ModalButtonSection from "../ui/ModalButtonSection";
import {useToast} from "../../utils/contexts/toast-context";
import {motion} from 'framer-motion';
import {useQueryClient} from 'react-query';

type DeleteCategoryModalProps = {
    formStyle: any;
    DeleteFormCloser: () => void;
};
const DeleteCategoryModal: React.FC<DeleteCategoryModalProps> = ({DeleteFormCloser, formStyle}) => {
    const queryClient = useQueryClient();
    const {closeDeleteCategoryModal, selectedCategoryId, removeCategory} = useCategory();
    const {displayMessage} = useToast();
    const {call, loading} = useApi();

    const handleDelete = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (selectedCategoryId) {
                await call(
                    () => doDeleteCategories(selectedCategoryId),
                    (deleted_category) => {
                        removeCategory(deleted_category);
                        displayMessage('Categoria eliminata');
                        closeDeleteCategoryModal();
                        DeleteFormCloser();
                    }
                )
            }
            await queryClient.invalidateQueries('getCategories')
            await queryClient.invalidateQueries('getArticles')
        } catch (error) {
            console.error("Error on Category delete:", error);
        }
    }


    return (
        <motion.div
            className="bg-[black] backdrop-blur-[4px] backdrop-filter bg-opacity-5 rounded p-4 flex justify-center items-center absolute z-[999]"
            style={formStyle} initial={{opacity: 0}} animate={{opacity: 1}}>
            {/*<div>Confermare la cancellazione della categoria?</div>*/}
            <form>
                <ModalButtonSection loading={loading}
                                    loadingLabel='Cancellazione in corso'
                                    onUndo={DeleteFormCloser}
                                    onConfirm={handleDelete}
                />
            </form>

        </motion.div>

    );
};

export default DeleteCategoryModal;