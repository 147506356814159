import React, {useState} from "react";

type ColorContextType = {
    selectedColorId: number | undefined,
    selectColorById: (id: number) => void,

    createColorFormStatus: boolean,
    openCreateColorForm: () => void,
    closeCreateColorForm: () => void,

    editColorFormStatus: boolean,
    openEditColorForm: () => void,
    closeEditColorForm: () => void,

    deleteColorFormStatus: boolean,
    openDeleteColorForm: () => void,
    closeDeleteColorForm: () => void,

    getSelectedColorFromArticle: (article: any) => any | null
}

const ColorContext = React.createContext<ColorContextType | undefined>(undefined);

function useColorContextValue() {
    const [selectedColorId, setSelectedColorId] = useState<number | undefined>();
    const selectColorById = (id: number) => setSelectedColorId(id);

    const [createColorFormStatus, setCreateColorFormStatus] = useState(false);
    const [editColorFormStatus, setEditColorFormStatus] = useState(false);
    const [deleteColorFormStatus, setDeleteColorFormStatus] = useState(false);

    const openCreateColorForm = () => setCreateColorFormStatus(true);
    const closeCreateColorForm = () => setCreateColorFormStatus(false);

    const openEditColorForm = () => setEditColorFormStatus(true);
    const closeEditColorForm = () => setEditColorFormStatus(false);

    const openDeleteColorForm = () => setDeleteColorFormStatus(true);
    const closeDeleteColorForm = () => setDeleteColorFormStatus(false);

    const getSelectedColorFromArticle = (article: any): any | null => {
        const _color = article.colors.find((color: any) => color.id == selectedColorId);
        if(_color) return _color;
        
        else return null;
    }


    return {
        selectedColorId, selectColorById,
        createColorFormStatus, openCreateColorForm, closeCreateColorForm,
        editColorFormStatus, openEditColorForm, closeEditColorForm,
        deleteColorFormStatus, openDeleteColorForm, closeDeleteColorForm,
        getSelectedColorFromArticle,
    }
}

function ColorProvider({children}: {children: React.ReactNode}) {
    const value = useColorContextValue();
    return <ColorContext.Provider value={value}>{children}</ColorContext.Provider>;
}

function useColor() {
    const context = React.useContext(ColorContext);
    if(!context) {
        throw new Error('useColor must be used within a ColorProvider');
    }
    return context;
}

export {
    ColorProvider,
    useColor,
}