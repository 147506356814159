import React, {useState, SyntheticEvent, useEffect, useRef, useLayoutEffect} from 'react';
import {FaTrash, FaSave} from "react-icons/fa";

import { IoReloadOutline } from 'react-icons/io5';
import {useCompany} from "../../utils/contexts/company-context";
import {useArticle} from "../../utils/contexts/article-context";
import {useColor} from "../../utils/contexts/color-context";
import ArticleDetails from "./ArticleDetails";
import useApi from '../../utils/custom_hooks/api';
import {doPutArticle} from "../../logic/articles";
import {doPutTranslation} from '../../logic/translations';
import {useToast} from '../../utils/contexts/toast-context';
import useApiGetArticles from '../../utils/custom_hooks/useApiGetArticles';
import ImageUploadButton from '../ui/ImageUploadButton';
import {motion, AnimatePresence} from 'framer-motion';
import ConditionalOverlay from '../ui/ConditionalOverlay';
import EditColorForm from './EditColorForm';
import DashButton from './DashButton';
import {IoIosArrowForward} from 'react-icons/io';
import {useQueryClient} from "react-query";
import DeleteArticleModal from './DeleteArticleModal';


type Props = {
    article: any,
}

const ArticleRow = ({article}: Props) => {
    const {displayMessage, displayError} = useToast();

    const {selectedArticle, selectedArticleId, updateArticle, selectLang, checkSameName, selectArticleById, loadArticles} = useCompany();
    const {openCreateColorForm} = useColor();
    const {call} = useApi();
    const queryClient = useQueryClient()
    
    
    

    const {data, error, isLoading} = useApiGetArticles();
    if (error) {
        displayError(error.message)
    }

    useEffect(() => {
        if (data) {
            loadArticles(data);
        }
    }, [data]);


    const [openColorAccordion, setOpenColorAccordion] = useState(false);

    const {
        openEditArticleForm,
        openDeleteArticleForm,
        isCreateArticleButtonOpen,
        openCreateArticleButton,
        deleteArticleFormStatus
    } = useArticle();


    const handleDelete = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        selectArticleById(article.id);
        openDeleteArticleForm();
        setOpenDelete(true);
    }
    const [openDelete, setOpenDelete] = useState(false)
    const DeleteFormCloser = () => setOpenDelete(false);


    const [isActive, setIsActive] = useState(article.active);
    const [isChanging, setIsChanging] = useState(false)


    const nameRefIta = React.useRef<HTMLInputElement>(null);
    const nameRefEn = React.useRef<HTMLInputElement>(null);
    const descriptionRefIta = React.useRef<HTMLTextAreaElement>(null);
    const descriptionRefEn = React.useRef<HTMLTextAreaElement>(null);


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        selectArticleById(article.id);
        const {type, name, value, checked} = e.target;
        if (type === 'checkbox' && name === 'is_active') {
            setIsActive(e.target.checked)
        }
        if (nameRefEn.current && (nameRefEn.current.value != (article?.['translations']?.[2]?.['text'])) && checkSameName(data, 2, nameRefEn, article) == false) {
            const name = nameRefEn.current.value;
            const data = {
                name
            }
            await call(
                () => doPutTranslation('articles', article.id, 'name', 'en', data),
                (res) => {
                    if (res) {
                        displayMessage('Articolo modificato');
                    }
                },
                (err) => {
                }
            )
        }else{if(checkSameName(data, 2, nameRefEn, article) == true){displayError('Nome Inglese già in uso')}}

        if (nameRefIta.current && nameRefIta.current.value != (article?.['translations']?.[3]?.['text']) && checkSameName(data, 3, nameRefIta, article) == false) {
            const name = nameRefIta.current.value;
            const data = {
                name
            }
            await call(
                () => doPutTranslation('articles', article.id, 'name', 'it', data),
                (res) => {
                    if (res) {
                        displayMessage('Articolo modificato');
                    }
                },
                (err) => {
                }
            )
        }else{if(checkSameName(data, 3, nameRefIta, article) == true){displayError('Nome italiano già in uso')}}

        if (descriptionRefEn.current && descriptionRefEn.current.value != (article?.['translations']?.[0]?.['text'])) {
            const description = descriptionRefEn.current.value;
            const data = {
                description
            }
            await call(
                () => doPutTranslation('articles', article.id, 'description', 'en', data),
                (res) => {
                    if (res) {
                        displayMessage('Articolo modificato');
                    }
                },
                (err) => {
                }
            )
        }

        
        if (descriptionRefIta.current && descriptionRefIta.current.value != (article?.['translations']?.[1]?.['text'])) {
            const description = descriptionRefIta.current.value;
            const data = {
                description
            }
            await call(
                () => doPutTranslation('articles', article.id, 'description', 'it', data),
                (res) => {
                    if (res) {
                        displayMessage('Articolo modificato');
                    }
                },
                (err) => {
                }
            )
        }
        queryClient.invalidateQueries('getArticles')
        setIsChanging(false)
                
        selectArticleById(undefined)
        
    }

    const DashSubmit = async (e: any) => {
        setIsActive(e.target.checked);
        e.preventDefault();
        if (true) {
            const active = e.target.checked

            const data = {
                active
            }

            await call(
                () => doPutArticle(article.id, data),
                (res) => {
                    if (res) {
                        //updateArticle(res);


                    }
                },
                (err) => {
                }
            )
        }
        await queryClient.invalidateQueries('getArticles')
    }


    const setFormValues = () => {
        if (nameRefIta.current && nameRefEn.current && descriptionRefIta.current
            && descriptionRefEn.current) {
            nameRefIta.current.value = (article?.['translations']?.[3]?.['text'])
            nameRefEn.current.value = (article?.['translations']?.[2]?.['text'])
            descriptionRefIta.current.value = (article?.['translations']?.[1]?.['text'])
            descriptionRefEn.current.value = (article?.['translations']?.[0]?.['text'])
        }
    }

    useEffect(() => {
        setFormValues();
    }, []);

    const container_style = {
        backgroundImage: `url("${article.image_url}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    const handleOpenColorAccordion = () => {
        selectLang('it')
        if (article.id !== selectedArticleId) {
            selectArticleById(article.id);
            setOpenColorAccordion(true);
        } else {
            selectArticleById(undefined);
            setOpenColorAccordion(false);
        }

    }

    const motionrow = {
        type: "spring",
        stiffness: 700,
        damping: 80,
    };
    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 40,
    };
    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        },
        exit: {y: 20, opacity: 0}

    }

    const [deleteModalStyle, setDeleteModalStyle] = useState<React.CSSProperties>({});
    const rowRef = React.useRef<HTMLTableRowElement>(null);
    useLayoutEffect(() => {
        setTimeout(function () {
            if (rowRef.current) {
                const {left, top, height, width} = rowRef.current.getBoundingClientRect();

                const left2 = left;
                const newDeleteModalStyle: React.CSSProperties = {
                    position: `absolute`,
                    left: `${0}px`,

                    height: `${height}px`,
                    width: `100%`
                };
                setDeleteModalStyle(newDeleteModalStyle);
            }
        })
    }, []);

    useEffect(() => {
        if (selectedArticleId === article.id) {
            // setOpen(true);
            openCreateArticleButton(true);
        }
    }, [selectedArticleId]);

const detailsRef = useRef<HTMLTableRowElement | null>(null);

//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (detailsRef.current && !detailsRef.current.contains(event.target as Node)) {
        
//         selectArticleById(undefined);
//       }
//     };
//     window.addEventListener('click', handleClickOutside);
//     return () => {
//       window.removeEventListener('click', handleClickOutside);
//     };
//   }, []);

  
    

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (detailsRef.current && !detailsRef.current.contains(event.target as Node)) {
        
        selectArticleById(undefined);
      }
    };
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const [isClicked, setIsClicked] = useState(false);
  const handleOnClick = () => {
    setIsClicked(true);
    setTimeout(() => {
        setIsClicked(false);
    }, 0.5);
    setFormValues();

};
    return (
        <>
            <motion.tr className='overflow-hidden shadow-xl bg-white h-[100px]' layout transition={motionrow}
                       variants={item} ref={rowRef}>

                <motion.td className="duration-[100ms] rounded-l-[5px] border-0 items-center justify-center">
                    <div className="object-fill flex justify-center">
                        <motion.button className=' text-[black] origin-center'
                                       animate={openColorAccordion && selectedArticleId === article.id ? {rotate: 90} : {}}
                                       transition={spring}
                                       onClick={handleOpenColorAccordion}><IoIosArrowForward className='scale-[2]'/>
                        </motion.button>
                    </div>
                </motion.td>

                <td className="">
                    <div className="object-fill flex justify-center gap-[10px] items-center mx-[5px]">
                        {/*<input type="checkbox" name="is_active" checked={isActive} onChange={handleChange} />*/}
                        <DashButton isActive={isActive} onChange={DashSubmit}/>
                        {/*<div>{article.id}</div>*/}
                        {isActive ? <label htmlFor="is_active" className='min-w-[54px]'> Attivo </label> :
                            <label htmlFor="is_active" className='min-w-[54px] opacity-60'> Inattivo </label>}</div>
                </td>

                {/*<td className='flex flex-col items-end w-full h-full justify-center  pt-[4px] '>
                    <div className='flex flex-col gap-[25px]  justify-center'><p>Nome</p><p>Descrizione</p></div></td>*/}

                <td className="font-medium py-[5px]">
                    <div className="object-fill flex flex-col justify-center items-center">
                        <div className='w-[fit-content]'>
                            <div className="">{
                                article.translations.map((obj: any, index: any) => {
                                    if (obj.lang == 'it')
                                        return (obj.field == 'name') ? (

                                            <p title="Nome" key={index + 1}
                                               className='px-0 py-1 flex flex-row gap-[10px]'>

                                                {/*<ReactCountryFlag countryCode={(obj.lang === 'en') ? 'gb' : obj.lang} style={{ width: '2em', height: '2em', }} svg />*/}
                                                <input
                                                    className="cursor-default focus:cursor-text border-[1px] border-[lightgray] rounded-[5px] px-[5px]"
                                                    placeholder={(obj.lang === 'en') ? 'Name' : 'Nome'}
                                                    name="name"
                                                    ref={(obj.lang === 'en') ? nameRefEn : nameRefIta}
                                                    onChange={() => setIsChanging(true)}
                                                    
                                                    onBlur={handleSubmit}
                                                />
                                            </p>
                                        ) : ''

                                })

                            }</div>
                            <div className="">{
                                article.translations.map((obj: any, index: any) => {
                                    if (obj.lang == 'it')
                                        return (obj.field == 'description') ? (

                                            <p title="descrizione" key={index + 1}
                                               className='px-0 py-1 flex flex-row items-center gap-[20px]'>

                                            <textarea
                                                className="cursor-default focus:cursor-text w-full bg-[white] border-[1px] border-[lightgray] shadow-[lightgray] shadow-inner rounded-[5px] p-[3px]"
                                                placeholder={(obj.lang === 'en') ? 'Description' : 'Descrizione'}
                                                name="description"
                                                ref={(obj.lang === 'en') ? descriptionRefEn : descriptionRefIta}
                                                onChange={() => setIsChanging(true)}
                                                onBlur={handleSubmit}
                                            />
                                            </p>
                                        ) : ''
                                })
                            }
                            </div>
                        </div>
                    </div>
                </td>


                <td className="font-medium">
                    <div className="object-fill flex flex-col justify-center items-center">
                        <div className='w-[fit-content] '>
                            {
                                article.translations.map((obj: any, index: any) => {
                                    if (obj.lang == 'en')
                                        return (obj.field == 'name') ? (
                                                <p title="Name" key={index + 1}
                                                   className='px-6 py-1 flex flex-row gap-[10px]'>
                                                    {/*<ReactCountryFlag countryCode={(obj.lang === 'en') ? 'gb' : obj.lang} style={{ width: '2em', height: '2em', }} svg />*/}
                                                    <input
                                                        className="cursor-default focus:cursor-text border-[1px] border-[lightgray] rounded-[5px] px-[5px]"
                                                        placeholder={(obj.lang === 'en') ? 'Name' : 'Nome'}
                                                        name="name"
                                                        ref={(obj.lang === 'en') ? nameRefEn : nameRefIta}
                                                        onChange={() => setIsChanging(true)}
                                                        onBlur={handleSubmit}
                                                    />
                                                </p>
                                            )
                                            : ''

                                })

                            }
                            {

                                article.translations.map((obj: any, index: any) => {
                                    if (obj.lang == 'en')
                                        return (obj.field == 'description') ? (
                                            <p title="Description" key={index + 1}
                                               className='px-6 py-1 flex flex-row items-center gap-[20px]'>
                                            <textarea
                                                className="cursor-default focus:cursor-text w-full bg-[white] border-[1px] border-[lightgray] shadow-[lightgray] shadow-inner rounded-[5px] p-[3px]"
                                                placeholder={(obj.lang === 'en') ? 'Description' : 'Descrizione'}
                                                name="description"
                                                ref={(obj.lang === 'en') ? descriptionRefEn : descriptionRefIta}
                                                onChange={() => setIsChanging(true)}
                                                onBlur={handleSubmit}
                                            />
                                            </p>
                                        ) : ''
                                })
                            }

                        </div>
                    </div>
                </td>


                <td>
                    <div className="object-fill flex justify-center">
                        <div className=" flex min-h-[90px] w-[168px] items-center">

                            <div
                                className="flex flex-row items-center justify-center h-full w-full bg-[#e5e7eb] rounded-lg ">
                                <div style={container_style}
                                     className="w-full h-full rounded flex items-center justify-center">

                                    <ImageUploadButton id={article.id} type='articles'></ImageUploadButton>
                                </div>
                            </div>

                        </div>
                    </div>
                </td>
                <td>
                    <div className="object-fill flex justify-center">
                        <div className="flex flex-row items-center">
                            <div
                                className={`w-full m-[4px] rounded-[5px] h-6  font-medium flex flex-col justify-center items-center min-w-[50px] ${article.colors.length > 0 ? "bg-gray-300" : "bg-red-400 text-white"}`}>
                                {article.colors.length}
                            </div>
                        </div>
                    </div>
                </td>
                <td className={openColorAccordion ? 'rounded-tr-[5px] duration-[100ms]' : 'rounded-r-[5px] duration-[100ms]'}>
                    <div className="object-fill flex justify-center">
                        <div className="flex flex-row items-center gap-[5px] duration-[300ms]">

                            {/* SALVATAGGIO MANUALE */}
                            {/* {isChanging ?
                                <div
                                    className="cursor-pointer h-6 w-6 flex justify-center items-center hover:text-[green] rounded-[6px] duration-[150ms] active:scale-[0.9] scale-[0.95]">
                                    <FaSave onClick={handleSubmit}
                                            title="Salva"
                                            className="h-5 w-5"/></div> : <div
                                    className="h-6 w-6 flex justify-center items-center  rounded-[6px] opacity-0 duration-[300ms] scale-[0.95]">
                                    <FaSave
                                        title="Salva"  
                                        className="h-5 w-5"/></div>} */}
{/* <div className='scale-[1.5] text-[gray] cursor-pointer mr-[5px]'>
                        <motion.div onClick={handleOnClick}
                                    animate={isChanging ? {rotate: 0, opacity: 1} : {rotate: -90, opacity: 0}}>
                            <IoReloadOutline className='-scale-x-100'/></motion.div>
                    </div> */}
                            
                            {article.colors && article.colors.length > 0 ? <label title="elimina i colori per eliminare"
                                                                                  className="h-6 w-6 flex justify-center items-center text-[#e70c0c] rounded-[6px]  duration-[300ms] opacity-30"><FaTrash
                                    className="h-4 w-4"/></label>
                                : <div
                                    className="cursor-pointer h-6 w-6 flex justify-center items-center text-[#e70c0c] rounded-[6px] hover:scale-[1.1]  duration-[300ms]">
                                    <FaTrash onClick={handleDelete} className="h-4 w-4"/>
                                </div>}
                            {openDelete ? <DeleteArticleModal DeleteFormCloser={DeleteFormCloser}
                                                              formStyle={deleteModalStyle}/> : ''}

                        </div>

                    </div>

                </td>


            </motion.tr>
            <tr className={openColorAccordion ? '' : 'h-[10px] w-full'}></tr>
           
            {openColorAccordion &&
                <motion.tr initial={{scaleY: 0}} animate={openColorAccordion ? {scaleY: 1} : {scaleY: 0}} 
                           transition={spring}
                           className='origin-top'
                           ><ArticleDetails article={article}/></motion.tr>
            }

            <div className={openColorAccordion ? 'h-[10px] w-full' : ''}></div>
      
            
        </>


    );
};

export default ArticleRow;
