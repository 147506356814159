import React, {useContext, useState} from "react";
import {doPostLogin, doPostRefreshToken} from "../../logic/auth";
import {useNavigate} from "react-router-dom";
import { useToast } from "./toast-context";
type ProviderProps = {
    children: React.ReactNode,
}

type AuthContextType = {
    token: string,
    refreshToken: string,
    signin: (user: string, password: string) => void,
    signout: () => void,
    doRefreshToken: () => Promise<boolean>,
    isAuthenticated: boolean,
    setIsAuthenticated: (isAuthenticated:boolean) => void,
    loadAuthenticationData: () => void,
    saveAuthenticationData:(data: any, successPath: string) => void,
}

const defaultAuthContextValue: AuthContextType = {
    token: "",
    refreshToken: "",
    signin: () => null,
    signout: () => null,
    doRefreshToken: () => Promise.resolve(false),
    isAuthenticated: false,
    setIsAuthenticated: () => false,
    loadAuthenticationData: () => null,
    saveAuthenticationData:() => null,
    
}

const authContext = React.createContext<AuthContextType | undefined>(undefined);

function ProvideAuth({children}: ProviderProps) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

function useAuth() {
    const context = useContext(authContext);
    
    if (!context) {
        throw new Error('useAuth must be used within an authProvider');
    }
    return context;
}

function useProvideAuth() {
    const [token, setToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    
    const navigate = useNavigate();

    const signin = async (user: string, password: string) => {
        let response = await doPostLogin(user, password);
        
        if (response) {
            //login success
            setIsAuthenticated(true)
            let {data} = response;
            if (data) {
                saveAuthenticationData(data, '/articles');
            }

        } else {
            console.warn('Login not valid');
           
            setIsAuthenticated(false)
        }
    }

    const signout = () => {
        setToken('');
        setRefreshToken('');
        localStorage.clear();
        setIsAuthenticated(false);
        
    }

    async function doRefreshToken (): Promise<boolean> {
        if(!refreshToken) {
            let refresh_saved = await localStorage.getItem('@refresh_token');
            if(refresh_saved) {
                await setRefreshToken(refresh_saved);
            }
        }

        let response = await doPostRefreshToken(refreshToken);
        if(response) {
            let {data} = response;
            if (data.token && data.refresh_token) {
                //TODO go to correct path
                localStorage.setItem('@access_token', data.token);
                localStorage.setItem('@refresh_token', data.refresh_token);
                setToken(data.token);
                setRefreshToken(data.refresh_token);
                return true;
            }
        }

        return false;
    }

    const saveAuthenticationData = (data: any, successPath: string) => {
        if (data.token && data.refresh_token) {
            localStorage.setItem('@access_token', data.token);
            localStorage.setItem('@refresh_token', data.refresh_token);
            setToken(data.token);
            setRefreshToken(data.refresh_token);
            navigate(successPath);
        }
    }

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    async function loadAuthenticationData() {
        const access = await localStorage.getItem('@access_token');
        const refresh = await localStorage.getItem('@refresh_token');

        if(access) await setToken(access);
        if(refresh) await setRefreshToken(refresh);
    }

    return {
        token, refreshToken,
        signin, signout, doRefreshToken, isAuthenticated, setIsAuthenticated, loadAuthenticationData, saveAuthenticationData
    }
}

export {
    ProvideAuth,
    useAuth
}