import {API} from "./api_config";

async function doPutTranslation(type:string, type_id:number, field:string, lang:string, data?: any) {

    return API.put(`/v1/backoffice/translations/${type}/${type_id}/${field}/${lang}`, data);
}


export {
    doPutTranslation,
}