import React from 'react';
import useApi from "../../utils/custom_hooks/api";
import {useCompany} from "../../utils/contexts/company-context";
import {doDeleteAssignedCategory, doPostAssignedCategory} from "../../logic/categories";
import Loading from "../ui/Loading";
import {motion} from 'framer-motion';
import {useQueryClient} from "react-query";
import {ITranslation} from "../../utils/interfaces/ITranslation";

type Props = {
    category: any,
    article: any
}

const ToggleableCategory = ({category, article}: Props) => {

    const {updateArticle} = useCompany();
    const {call, loading} = useApi();
    const queryClient = useQueryClient()
    const {selectedLang} = useCompany();

    const translation = category.translations.find((translation: {
        lang: string;
        field?: string;
    }) => translation.lang === selectedLang && (translation.field === 'name' || !translation.field));
    
    const translationName = translation ? translation.text : category.name;


    const handleCategoryToggle = async (category: any) => {
        if (category.active) {
            await call(
                () => doDeleteAssignedCategory(article.id, category.id),
                (updated_article) => {
                    queryClient.invalidateQueries('getArticles')
                    if (updated_article) updateArticle(updated_article.article);
                }
            )
        } else {
            await call(
                () => doPostAssignedCategory(article.id, category.id),
                (updated_article) => {
                    queryClient.invalidateQueries('getArticles')
                    if (updated_article) updateArticle(updated_article.article);
                }
            )
        }
    }

    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        },
        exit: {y: 20, opacity: 0}

    }

    return (
        <motion.div variants={item} onClick={() => handleCategoryToggle(category)}
                    className={`p-1 rounded ${category.active ? "bg-green-400 font-bold" : "bg-gray-200"} hover:bg-gray-500 cursor-pointer flex flex-row items-center gap-1 duration-[150ms]`}>
            <div className="uppercase text-sm">{translationName}</div>
            {loading && <Loading label=''/>}
        </motion.div>
    );
};

export default ToggleableCategory;
