import React from 'react';
import {useState, useEffect} from 'react';
import ReactCountryFlag from 'react-country-flag';
import ArticleRow from "./ArticleRow";
import {useCompany} from "../../utils/contexts/company-context";
import {motion} from 'framer-motion';
import ArticleRowMobile from './ArticleRowMobile';
import {useArticle} from "../../utils/contexts/article-context";
import useApiGetArticles from "../../utils/custom_hooks/useApiGetArticles";
import {useToast} from "../../utils/contexts/toast-context";

const Table = () => {
    const {displayError} = useToast()
    const [windowSize, setWindowSize] = useState<boolean>(true);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 987;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);


    const {loadArticles} = useCompany();

    const {data, error, isLoading} = useApiGetArticles();
    if (error) {
        displayError(error.message)
    }

    useEffect(() => {
        if (data) {
            loadArticles(data);
        }
    }, [data]);

    const container = {
        hidden: {opacity: 0, scale: 1},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.1,
                staggerChildren: 0.05
            }
        },
        exit: {y: 20, opacity: 0}
    }

    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        },
        exit: {y: 20, opacity: 0}

    }

    return (
        <>{windowSize ?

            <div className={'pb-[100px] justify-center flex flex-row'}>
                <motion.div className={'my-[10x] relative flex flex-row gap-[1rem] m-3 flex-wrap justify-center'}
                            variants={container} initial="hidden" animate="visible">
                    {data && data.length > 0
                        ? data.map((article) => <motion.div variants={item}
                                                            className='justify-center flex flex-col w-[100%]'>
                            <ArticleRowMobile article={article} key={article.id}/></motion.div>)
                        : <div>Nessun articolo</div>
                    }

                </motion.div>
            </div> :


            <table className={' overflow-auto w-full mt-[45px] mx-[10px]'}>

                <thead className='gap-[100px] bg-[#0c9bd2] bg-opacity-[100%] relative w-full'>

                <tr className='max-w-sm overflow-hidden shadow-xl relative text-white'>
                    <td className='border-0 rounded-l-[5px] '>
                        <div className="border-0 flex justify-center ">Apri</div>
                    </td>
                    <td className='border-0 '>
                        <div className="border-0 flex justify-center">Stato</div>
                    </td>
                    {/*<td></td>*/}
                    <td className='border-0 '>
                        <div className="border-0 flex justify-center gap-[7px]"><ReactCountryFlag countryCode={'it'}
                                                                                                  style={{
                                                                                                      width: '1.5em',
                                                                                                      height: '1.5em',
                                                                                                  }} svg/>Italiano
                        </div>
                    </td>

                    <td className='border-0 '>
                        <div className="border-0 flex justify-center gap-[7px]"><ReactCountryFlag countryCode={'gb'}
                                                                                                  style={{
                                                                                                      width: '1.5em',
                                                                                                      height: '1.5em',
                                                                                                  }} svg/>Inglese
                        </div>
                    </td>
                    <td className='border-0 '>
                        <div className="border-0 flex justify-center">Immagine</div>
                    </td>
                    <td className='border-0'>
                        <div className="border-0 flex justify-center">N. Colori</div>
                    </td>
                    <td className='rounded-r-[5px] '>
                        <div className="border-0 flex justify-center"></div>
                    </td>
                </tr>
                </thead>
                <div className='h-[15px] w-full'></div>
                <motion.tbody className='  relative overflow-auto' variants={container}
                              initial="hidden" animate="visible">
                    {data && data.length > 0
                        ? data.map((article) => <ArticleRow article={article} key={article.id}/>)
                        : <tr>
                            <td colSpan={4}>Nessun articolo</td>
                        </tr>
                    }

                </motion.tbody>


            </table>
        }
        </>

    );
};

export default Table;
