import React from 'react';

import {useRef, useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {FaAlignRight} from 'react-icons/fa';

type Props = {
    children: React.ReactNode,
}


const ModalPanel = ({children}: Props) => {
    const [overState, setOver] = useState(false);
    const [windowSize, setWindowSize] = useState<boolean>(false);
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 600;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);

    const DEFAULT_CLASS = " p-2 rounded-lg backdrop-blur-[6px] backdrop-filter bg-opacity-[20%] bg-black border-y-[1px] border-[1px] h-[450px] w-[350px] z-[100]";
    const MOBILE_CLASS = "p-2 rounded-lg backdrop-blur-[6px] backdrop-filter bg-opacity-[20%] bg-black border-[1px] absolute right-[25%] top-[25%] h-[fit] w-fit z-[100]";
    return (
        <>
            {windowSize ?
                <motion.div id="panel"
                            className={MOBILE_CLASS}
                            initial={{x: 0, opacity: 0, scale: 0}}
                            animate={{x: 0, opacity: 1, scale: 1}}
                            transition={{
                                type: "spring",
                                bounce: 0,
                                duration: 0.4
                            }}

                >
                    {children}
                </motion.div>

                :

                <motion.div id="panel"
                            className={DEFAULT_CLASS}
                            initial={{scale: 0, opacity: 0}}
                            animate={{scale: 1, opacity: 1}}
                            transition={{
                                type: "spring",
                                bounce: 0,
                                duration: 0.4
                            }}
                            exit={{x: 400,}}
                >
                    {children}
                </motion.div>


            }
        </>

    );
};

export default ModalPanel;