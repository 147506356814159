import React, {useEffect, useState, useRef} from 'react';
import Layout from "../components/ui/layout/Layout";
import useApi from "../utils/custom_hooks/api";
import Table from "../components/Articles/Table";
import {useCompany} from "../utils/contexts/company-context";
import Loading from "../components/ui/Loading";
import ConditionalOverlay from "../components/ui/ConditionalOverlay";
import EditColorForm from "../components/Articles/EditColorForm";
import {useColor} from "../utils/contexts/color-context";
import {useCategory} from "../utils/contexts/categories-context";
import {doPostArticle} from '../logic/articles';
import {useToast} from '../utils/contexts/toast-context';
import {motion} from 'framer-motion';
import {FaPlus} from 'react-icons/fa';
import {doGetWhoAmI} from "../logic/auth";
import useApiGetCategories from '../utils/custom_hooks/useApiGetCategories';


const ArticlesScreen = () => {

    const [windowSize, setWindowSize] = useState<boolean>(false);
    const {selectedArticleId} = useCompany();
    const isMobile = (): void => {
        const screenWidth: number = window.innerWidth;
        const isMobileSize: boolean = screenWidth < 768;
        setWindowSize(isMobileSize);
    };
    useEffect(() => {
        isMobile();
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        };
    }, []);


    const {call, loading} = useApi();
    const {editColorFormStatus, deleteColorFormStatus, createColorFormStatus} = useColor();
    const {addArticle} = useCompany();
    const {displayMessage, displayWarning, displayError} = useToast();


    const handleSubmit = async (e: any) => {
        e.preventDefault();

        await call(
            () => doPostArticle({name: '', description: '', weight: 0, image_url: ""}),
            (new_article) => {
                if (new_article) {
                    addArticle(new_article);
                    displayMessage("Articolo creato");
                }
            }
        )
    }


    const [isHovered, setIsHovered] = useState(false);

    const addButtonRef = useRef<HTMLButtonElement>(null);


    const handleResize = (entries: ResizeObserverEntry[]) => {
        const entry = entries[0];
        const {width} = entry.contentRect;

        if (width >= 102) {
            setIsHovered(true);
        } else {
            setIsHovered(false);
        }
    };
    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);

        if (addButtonRef.current) {
            resizeObserver.observe(addButtonRef.current);
        }

        return () => {
            if (addButtonRef.current) {
                resizeObserver.unobserve(addButtonRef.current);
            }
        };
    }, []);

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 40,
        duration: 150,

    };

    const {
        loadCategories,
    } = useCategory();
    const {data, error} = useApiGetCategories()
    useEffect(() => {
        if (data) {
            loadCategories(data);
        } else {
        }
    }, [data]);

    // todo: 2023/08/25, to be removed after full refactor
    const {initializeCompany} = useCompany();
    useEffect(() => {
        const load = async () => {
            if (!loading) {
                await call(
                    () => doGetWhoAmI(),
                    (res) => {
                        if (res.company) {
                            initializeCompany(res.company);
                        }
                    }
                )
            }

        }

        load();
    }, []);

    console.log('selectedArticleId', selectedArticleId)
    return (
   
        <Layout title="Articles">

                        <motion.button
                            initial={{borderRadius: 100, width: 50}} whileHover={{borderRadius: 5, width: 115}}
                            transition={spring} ref={addButtonRef} onClick={handleSubmit}
                            className={selectedArticleId ? "hidden" : "fixed bg-[#3782f1] text-[#fafcff] cursor-pointer bg-opacity-70 roundedn-full h-[50px] z-50 items-center justify-center p-1 flex items-center rounded-full hover:bg-opacity-100 bottom-[5.5%] right-[1.5%] origin-right backdrop-blur-[2px] backdrop-filter bg-opacity-20"}>
                            {isHovered ? <div className='font-bold'>Crea Nuovo</div> :
                                <FaPlus className='scale-[1.5]'/>}
                        </motion.button>

            {loading ?
                <Loading/>
                :
                <div className='w-full flex justify-center items-start h-[92vh] overflow-auto scrollbar-none '>
                    <Table/></div>

            }


            {/* <ConditionalOverlay condition={deleteArticleFormStatus}>
                <DeleteArticleModal/>
            </ConditionalOverlay> */}

            {/* <ConditionalOverlay condition={editColorFormStatus}>
                <EditColorForm />
            </ConditionalOverlay> */}


        </Layout>

    );
};

export default ArticlesScreen;